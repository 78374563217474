import axios from '../core/HttpClient';

 function  fetchData ( startDate, endDate, onlyActive, groupBy, url, handle, details, byOne, detailsByOne ){
    return axios.get( url, {
        params: {
            startDate: startDate.format(),
            endDate: endDate.format(),
            onlyActive: onlyActive,
            groupBy: groupBy,
            handle: handle,
            details: details,
            byOne: byOne,
            detailsByOne: detailsByOne
        }
    } )
}

export default fetchData;