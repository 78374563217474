import React from "react";
import { Row } from "antd";

export default function PrintHeader(props) {
  return (
    <div className="print_header">
      <Row type="flex" justify="space-between">
        <div className="logo">
          <img src="\public\logo.png" alt="Logo" />
        </div>
        <div>
          <p>Elitex Systems LP</p>
          <p>35 Claverhouse Drive,</p>
          <p>Edinburgh, United Kingdom,</p>
          <p>EH16 6BX</p>
          <a href="http://elitex.systems/">http://elitex.systems/</a>
        </div>
      </Row>
      {props.groupBy ? (
        <div>
          <strong>Group by:</strong> {props.groupBy}
        </div>
      ) : null}
      <div>
        <strong>Period:</strong> {props.startDate} {props.fromYear} - {props.endDate}{" "}
        {props.toYear}
      </div>
    </div>
  );
}
