import React, { Component } from "react";
import NoLogtime from "../../../components/NoLogtime";
import Loader from "../../../components/Loader";
import TableHeader from "../../../components/ReportPage/TableHeader"
import fetchData from "../../../services/fetchData";
import { getProjects } from "../../../services/APIsersices";
import {
  convertProjectResponseForReport,
  convertClientResponse
} from "../../../helpers/convertResponse";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

const url = "/api/users/logTime/reports";

class ClientContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderClient = this.renderClient.bind(this);
  }

  componentDidMount() {
    const { startDate, endDate, onlyActive, groupBy } = this.props;
    if (this.props.match.params.id) {
      this.fetcClientecthData(
        startDate,
        endDate,
        onlyActive,
        groupBy,
        this.props.match.params.id
      );
    } else {
      this.fetcClientecthData(startDate, endDate, onlyActive, groupBy);
    }
    this.fetcAllClientecthData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.startDate !== nextProps.startDate ||
      this.props.endDate !== nextProps.endDate ||
      this.props.onlyActive !== nextProps.onlyActive ||
      this.props.match.params.id !== nextProps.match.params.id) {
      const { startDate, endDate, onlyActive, groupBy } = nextProps;
      this.fetcClientecthData(
        startDate,
        endDate,
        onlyActive,
        groupBy,
        nextProps.match.params.id
      );
    }
  }

  fetcClientecthData(startDate, endDate, onlyActive, groupBy) {
    fetchData(startDate, endDate, onlyActive, groupBy, url)
      .then(response => {
        const proj = convertProjectResponseForReport(response, groupBy);
        this.setState({
          proj
        });
        sessionStorage.setItem("groupBy", groupBy);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetcAllClientecthData() {
    getProjects()
      .then(response => {
        const clientsAll = convertClientResponse(response);
        this.setState({ clientsAll });
      })
      .catch(error => {
        console.log("Error occured in componentWillMount");
        console.log(error);
      });
  }

  renderClient(target, index) {
    let logtimes
    if (this.state.proj) {
      logtimes = this.state.proj[target];
    }
    let count = 0;
    let id;
    let clientName;
    if (logtimes) {
      logtimes.forEach(log => {
        Object.keys(log.logTimeMap).forEach(time => {
          const date = time;
          const amount = log.logTimeMap[date].amount;
          count += amount;
          id = log.clientName;
          clientName = log.clientName;
        });
      });
    }
    return (
      <tr className="ant-table-row ant-table-row-level-0" key={index}>
        {target === id ? (
          <React.Fragment>
            <td>
              <Link to={`/report/client/${id}`}>{clientName}</Link>
            </td>
            <td>
              <Link to={`/report/details/${clientName}`} title="Detailed report"> {count}</Link>
            </td>
          </React.Fragment>
        ) : (
            <React.Fragment>
              <td> {target}</td>
              <td>0</td>
            </React.Fragment>
          )}
      </tr>
    );
  }

  render() {
    const data = this.state.clientsAll;

    if (!data) {
      return <Loader />;
    } else {
      if (Object.keys(data).length === 0) {
        return <NoLogtime />;
      } else {
        return (
          <React.Fragment>
            <div className="ant-table-wrapper gx-table-responsive">
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table ant-table-default ant-table-scroll-position-left log_time">
                    <div className="ant-table-content">
                      <div className="ant-table-body"></div>
                      <table id="table-to-xls">
                        <thead className="ant-table-thead">
                          <TableHeader columns={['Client', 'Time']} class="bg-prim" />
                        </thead>
                        <tbody className="ant-table-body">
                          {Object.keys(data).map(this.renderClient)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment >
        );
      }
    }
  }
}

export default withRouter(ClientContent);
