import React, { Component } from "react";
import ActionButton from "../../components/ActionButton";
import Select from "react-select";
import EditText from "../../components/ProjectTable/EditText";
import {
  changeUserData,
  getUsers,
  getProjects,
  updateProjData
} from "../../services/APIsersices";
import { Card, Table, Checkbox, Input, Button, Icon } from "antd";
import { openNotificationWithIcon } from "../../helpers/helpers";

import { dynamicSort } from "../../helpers/helpers";

const Search = Input.Search;

const columns = [
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName"
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName"
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email"
  },
  {
    title: "Activate",
    dataIndex: "activate",
    key: "activate"
  },
  {
    title: "Projects",
    dataIndex: "projects",
    key: "projects"
  },
  {
    title: "Invite",
    dataIndex: "invite",
    key: "invite"
  }
];

class UserTable extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      projects: [],
      page: 0,
      rowsPerPage: 5,
      searchString: "",
      onlyActive: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    getUsers().then(response => {
      const users = response.data.map(user => ({
        ...user,
        isUpdating: false
      }));
      users.sort(dynamicSort("-isActive"));
      if (this._isMounted) {
        this.setState({ users });
      }
    });

    getProjects().then(response => {
      const projects = response.data;
      if (this._isMounted) {
        this.setState({ projects });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeUserProjects = response => {
    let projectIds = response.data.projects;
    const projects = this.state.projects;

    const findProjectById = id => projects.find(proj => proj._id === id);
    const selectedProjects = projectIds.map(findProjectById);

    var users = this.state.users.map(function(user) {
      return user._id === response.data._id
        ? { ...response.data, isUpdating: false, projects: selectedProjects }
        : user;
    });
    return users;
  };

  handleClick = user => {
    user.isActive
      ? openNotificationWithIcon("info", "Deactivated")
      : openNotificationWithIcon("info", "Activated");

    this.setState({ isUpdating: true });

    const data = {
      id: user._id,
      text: !user.isActive,
      fieldName: "isActive"
    };

    changeUserData(data)
      .catch(error => {
        console.log("Failed to update");
      })
      .then(response => {
        const users = this.changeUserProjects(response);
        this.setState({
          users,
          isUpdating: false
        });
      });
  };

  handleChange = (selected, user) => {
    const users = this.state.users.map(person => {
      return person._id === user._id
        ? {
            ...user,
            projects: selected
          }
        : person;
    });

    this.setState({ users });
  };

  changeUser = (id, fieldName) => {
    return text => {
      if (!text) {
        return;
      }
      const data = { id, text, fieldName };

      changeUserData(data)
        .catch(error => {
          console.log("Failed to update the user");
        })
        .then(response => {
          const users = this.state.users.map(element => {
            return element._id === response.data._id
              ? { ...response.data }
              : element;
          });

          this.setState({ users });

          switch (fieldName) {
            case "firstName":
              openNotificationWithIcon(
                "success",
                "First name successfully changed"
              );
              break;
            case "lastName":
              openNotificationWithIcon(
                "success",
                "Last name successfully changed"
              );
              break;
            case "email":
              openNotificationWithIcon("success", "Email successfully changed");
              break;
            default:
              return;
          }
        });
    };
  };

  handleSubmit = user => {
    const users = this.state.users.map(person => {
      return person._id === user._id
        ? {
            ...user
          }
        : person;
    });

    this.setState({ users });

    updateProjData({ user })
      .catch(error => {
        console.log(error);
      })
      .then(response => {
        const projectIds = response.data.projects;
        const projects = this.state.projects;

        const findProjectById = id => projects.find(proj => proj._id === id);
        const selectedProjects = projectIds.map(findProjectById);

        const users = this.state.users.map(person => {
          return person._id === response.data._id
            ? {
                ...response.data,
                projects: selectedProjects
              }
            : person;
        });

        this.setState({ users });

        openNotificationWithIcon("success", "Projects saved");
      });
  };

  handleSearch = value => {
    this.setState({
      searchString: value
    });
  };

  toggleChangeActive = () => {
    this.setState(prevState => ({
      onlyActive: !prevState.onlyActive
    }));
  };

  render() {
    const { rowsPerPage, page } = this.state;
    let users = this.state.users;
    let search = this.state.searchString.trim().toLowerCase();

    if (search.length > 0) {
      users = users.filter(user => {
        if (user.firstName.toLowerCase().match(search)) {
          return user.firstName.toLowerCase().match(search);
        } else if (user.lastName.toLowerCase().match(search))
          return user.lastName.toLowerCase().match(search);
      });
    }

    if (this.state.onlyActive) {
      users = users.filter(u => u.isActive === true);
    }

    const customStyles = {
      control: styles => ({
        ...styles,
        backgroundColor: "rgba(250, 250, 250, 0.1)",
        borderColor: "rgba(250, 250, 250, 0.1)",
        marginBottom: "15px"
      }),
      option: styles => {
        return {
          ...styles,
          backgroundColor: "inherit",
          cursor: "pointer"
        };
      },
      multiValue: styles => {
        const textColor = "black";
        return {
          ...styles,
          color: textColor,
          borderRadius: "5px"
        };
      }
    };

    const data = [];

    users.forEach((user, i) => {
      data.push({
        key: user._id,
        firstName: (
          <EditText
            value={user.firstName}
            callBack={this.changeUser(user._id, "firstName")}
          />
        ),
        lastName: (
          <EditText
            value={user.lastName}
            callBack={this.changeUser(user._id, "lastName")}
          />
        ),
        email: (
          <EditText
            value={user.email}
            callBack={this.changeUser(user._id, "email")}
          />
        ),
        activate: this.props.userData._id !== user._id && (
          <ActionButton
            data={user}
            value={user.isActive ? "Deactivate" : "Activate"}
            currentUserId={this.props.userData._id}
            handleClick={this.handleClick}
            isUpdating={this.state.isUpdating}
          />
        ),
        projects: (
          <React.Fragment>
            <Select
              getOptionLabel={project => {
                return `${project.projectName} - ${project.clientName}`;
              }}
              styles={customStyles}
              getOptionValue={project => project._id}
              isDisabled={user.isUpdating}
              value={user.projects}
              onChange={e => this.handleChange(e, user)}
              options={this.state.projects.filter(project => {
                if (!user.projects.includes(project._id)) {
                  return project;
                }
              })}
              className="customSelect"
              isMulti
            />
            <Button
              type="primary"
              onClick={() => this.handleSubmit(user)}
              disabled={user.isUpdating}
            >
              Submit
            </Button>
          </React.Fragment>
        ),
        invite: user.lastLoginDate ? (
          <Icon type="check" />
        ) : (
          <Icon type="close" />
        )
      });
    });
    return (
      <Card title="Users">
        <div>
          <Search
            placeholder="Search"
            onSearch={value => this.handleSearch(value)}
            style={{ width: 200 }}
          />
        </div>
        <Checkbox
          name="onlyActive"
          onChange={this.toggleChangeActive}
          checked={this.state.onlyActive}
        >
          Active users only
        </Checkbox>
        <Table
          className="gx-table-responsive"
          columns={columns}
          dataSource={data}
          bordered
          pagination={{
            count: Object.keys(users).length,
            defaultPageSize: rowsPerPage,
            page: page,
            onChangePage: this.handleChangePage
          }}
        />
      </Card>
    );
  }
}

export default UserTable;
