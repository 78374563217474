import React from "react";
import Button from "../Button";
import { Modal, Checkbox, Row, Col } from "antd";

export default function ModalForSelectColumn(props) {
  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Modal
      title="Choose columns"
      visible={props.showSelectColumn}
      onCancel={props.toggleModal}
      footer={[
        <Button
          key="1"
          action={props.toggleModal}
          value={"Select"}
          class={"btn btn-primary"}
        />,
        <Button key="2" action={props.toggleModal} value={"Close"} />
      ]}
    >
      <Row>
        {Object.keys(props.optionForCheckbox).map((i, index) => {
          return (
            <Col key={index} span={8}>
              <Checkbox
                name={i}
                onChange={props.handleChange(i)}
                checked={props.optionForCheckbox[i]}
              >
                {capitalizeFirstLetter(i)}
              </Checkbox>
            </Col>
          );
        })}
      </Row>
    </Modal>
  );
}
