import React from "react";
import { Button } from "antd";

export default function ButtonCustom (props) {
  return (
    <Button className={props.class} onClick={props.action} disabled={props.disabled}>
      {props.value}
    </Button>
  );
}
