import React, { Component } from "react";
import axios from "../../core/HttpClient";
import { connect } from "react-redux";
import { fetchRole } from "../../actions/actions";
import { Button, Card, Form, Input, Upload, Icon, Select } from "antd";
import {
  checkPassword,
  formItemLayout,
  openNotificationWithIcon
} from "../../helpers/helpers";

const Option = Select.Option;
const FormItem = Form.Item;

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.props.fetchRole();

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      selectedRoles: [],
      selectedFile: null,
      validPassw: false,
      showPassword: false
    };
  }

  fileChangedHandler = event => {
    this.setState({
      selectedFile: event.file.originFileObj
    });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  selectHandleChange = selectedRoles => {
    this.setState({
      selectedRoles: selectedRoles
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const formData = new FormData();

        if (this.state.selectedFile) {
          formData.append(
            "userImage",
            this.state.selectedFile,
            this.state.selectedFile.name
          );
        }

        formData.append("firstName", this.state.firstName);
        formData.append("lastName", this.state.lastName);
        formData.append("email", this.state.email.trim());
        formData.append("password", this.state.password);
        formData.append("idRole", this.state.selectedRoles);

        if (!checkPassword(this.state.password)) {
          this.setState({
            validPassw: true
          });
          return false;
        } else {
          const url = "/api/users";
          axios
            .post(url, formData)
            .then(response => {
              openNotificationWithIcon("success", "User successfully created");
              this.props.form.resetFields();
            })
            .catch(error => {
              if (error.response.status === 409) {
                openNotificationWithIcon("error", "User exist");
              }
            });
          this.setState({
            validPassw: false,
            requiredRole: false
          });
        }
      }
    });
  };

  render() {
    const roleOptions = this.props.roles.map(role => ({
      label: role.name,
      value: role._id
    }));

    const { getFieldDecorator } = this.props.form;

    const children = [];
    for (let i = 0; i < roleOptions.length; i++) {
      children.push(
        <Option key={roleOptions[i].value}>{roleOptions[i].label}</Option>
      );
    }

    return (
      <Card className="gx-card" id="darkSelect">
        <Form
          onSubmit={this.handleSubmit}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem {...formItemLayout} label="First Name">
            {getFieldDecorator("firstName", {
              value: this.state.firstName,
              rules: [
                {
                  required: true,
                  message: "Please input First Name"
                }
              ]
            })(<Input name="firstName" onChange={this.handleChange} />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Last Name">
            {getFieldDecorator("lastName", {
              value: this.state.lastName,
              rules: [{ required: true, message: "Please input Last Name" }]
            })(<Input name="lastName" onChange={this.handleChange} />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Email">
            {getFieldDecorator("email", {
              value: this.state.email,
              rules: [
                {
                  required: true,
                  type: "email",
                  message: "The input is not valid E-mail!"
                }
              ]
            })(<Input name="email" onChange={this.handleChange} />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Role">
            {getFieldDecorator("role", {
              rules: [{ required: true, message: "Please Select role" }]
            })(
              <Select
                mode="multiple"
                className="gx-mr-3 gx-mb-3"
                onChange={this.selectHandleChange}
              >
                {children}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Password">
            {getFieldDecorator("password", {
              value: this.state.password,
              rules: [{ required: true, message: "Please input Password" }]
            })(
              <Input
                type="password"
                name="password"
                onChange={this.handleChange}
              />
            )}
            {this.state.validPassw ? (
              <p>
                Password must contain minimum 6 characters, at least one letter
                and one number
              </p>
            ) : null}
          </FormItem>
          <FormItem {...formItemLayout} label="Upload photo">
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              onChange={this.fileChangedHandler}
            >
              <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
              </div>
            </Upload>
          </FormItem>
          <FormItem>
            <Button type="primary" className="gx-mb-0" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    roles: state.rolesReducer.roles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchRole: () => dispatch(fetchRole())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(UserForm));
