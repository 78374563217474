import React, { Component } from "react";
import {Button} from "antd";
class ActionButton extends Component {
  handleClick() {
    this.props.handleClick(this.props.data);
  }

  render() {
    return (
      <React.Fragment>
        <Button
          size="small"
          onClick={this.handleClick.bind(this)}
          visibility={this.props.visibility}
          type={this.props.isUpdating ? 'disabled' : 'primary'}
          disabled={this.props.isUpdating}
        >
          {this.props.value}
        </Button>
      </React.Fragment>
    );
  }
}

export default ActionButton;
