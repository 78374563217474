import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Tab from "./Tab";

class Tabs extends Component {
  constructor(props) {
    super(props);
    const { hash } = this.props.location;
    let active;
    if (hash) {
      active = hash.substr(1);
    }
    this.state = {
      activeTab: active ? active : this.props.children[0].props.label
    };
  }

  onClickTabItem = tab => {
    this.setState({ activeTab: tab });
  };

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab }
    } = this;

    return (
      <div className="ant-tabs ant-tabs-top ant-tabs-card">
        <div role="tablist" className="ant-tabs-bar ant-tabs-card-bar" tabIndex="0">
          <div className="ant-tabs-nav-container">
            <div className="ant-tabs-nav-wrap">
              <div className="ant-tabs-nav-scroll">
                <div className="ant-tabs-nav ant-tabs-nav-animated">
                  {children.map(child => {
                    const { label } = child.props;
                    return (
                      <Tab
                        activeTab={activeTab}
                        key={label}
                        label={label}
                        onClick={onClickTabItem}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ant-tabs-content ant-tabs-top-content ant-tabs-card-content" >
          {children.map(child => {
            if (child.props.label !== activeTab) return;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(Tabs);
