import "antd/dist/antd.css";
import "./stylesheets/dark-theme.css";

import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { Switch } from "react-router";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";
import { Layout } from "antd";

import UserTable from "./containers/UsersTable/UsersTable";
import UserForm from "./containers/User/UserForm";
import ProjectForm from "./containers/Project/ProjectForm";
import ProjectTable from "./containers/Project/ProjectTable";
import LoginPage from "./containers/LoginPage/LoginPage";
import LogoutPage from "./containers/Logout";
import UserTime from "./containers/UserTime/UserTime";
import ReportPage from "./containers/ReportPage/ReportPage";
import Navbar from "./containers/Navbar";
import ProfilePage from "./containers/ProfilePage/ProfilePage";
import ResetPasswPage from "./containers/ResetPasswPage/ResetPasswPage";
import Notification from "./containers/Notification/Notification";
import { fetchUserData, fetchNotific } from "./actions/actions";
import Loader from "./components/Loader";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const { Content } = Layout;

const ProtectedRoute = ({
  component: Component,
  allowedRoles,
  userData,
  ...rest
}) => {
  if (Object.keys(userData).length === 0 && cookies.get("token")) {
    return <Loader />;
  } else {
    let user = userData.idRole;
    return (
      <Route
        {...rest}
        render={props =>
          user ? (
            user.some(r => allowedRoles.indexOf(r.name) >= 0) ? (
              <Component {...props} userData={userData} />
            ) : null
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      msg: 0
    };
  }

  componentDidMount() {
    if (Object.keys(this.state.userData).length === 0 && cookies.get("token")) {
      this.props.fetchUserData();
      this.props.fetchNotific();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userData) {
      this.setState({
        userData: nextProps.userData
      });
    }
    if (nextProps.msg) {
      this.setState({
        msg: nextProps.msg
      });
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Layout className="gx-app-layout dark-theme">
          <Navbar
            userData={this.state.userData}
            notific={this.state.msg ? this.state.msg.length : null}
          />
          <Content className="gx-layout-content gx-container-wrap">
            <div className="gx-main-content-wrapper">
              <Switch>
                <Route path="/login" exact component={LoginPage} />
                <Route path="/logout" exact component={LogoutPage} />
                <ProtectedRoute
                  path="/projectForm"
                  userData={this.state.userData}
                  allowedRoles={["admin", "user"]}
                  component={ProjectForm}
                />
                <ProtectedRoute
                  path="/userTable"
                  userData={this.state.userData}
                  allowedRoles={["admin"]}
                  component={UserTable}
                />
                <ProtectedRoute
                  path="/projectTable"
                  userData={this.state.userData}
                  allowedRoles={["admin"]}
                  component={ProjectTable}
                />
                <ProtectedRoute
                  path="/userForm"
                  userData={this.state.userData}
                  allowedRoles={["admin"]}
                  component={UserForm}
                />
                <ProtectedRoute
                  path="/report"
                  userData={this.state.userData}
                  allowedRoles={["admin"]}
                  component={ReportPage}
                />
                <ProtectedRoute
                  path="/people"
                  userData={this.state.userData}
                  allowedRoles={["admin", "user"]}
                  component={ProfilePage}
                />
                <ProtectedRoute
                  path="/notification"
                  userData={this.state.userData}
                  allowedRoles={["admin"]}
                  component={Notification}
                />
                <Route path="/reset" component={ResetPasswPage} />
                <ProtectedRoute
                  path="/"
                  exact
                  userData={this.state.userData}
                  allowedRoles={["admin", "user"]}
                  component={UserTime}
                />
              </Switch>
            </div>
          </Content>
        </Layout>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.authenticationReducer.loggedIn,
    userData: state.authenticationReducer.userData,
    msg: state.notificationReducer.msg
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUserData: data => dispatch(fetchUserData(data)),
    fetchNotific: data => dispatch(fetchNotific(data))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
