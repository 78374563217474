import Cookies from "universal-cookie";
import {openNotificationWithIcon} from '../helpers/helpers';
import {
  getOneUser,
  getRoles,
  authentication,
  getNotification
} from "../services/APIsersices";

export const ACTIVATE = "ACTIVATE";
export const DEACTIVATE = "DEACTIVATE";
export const SUBMIT = "SUBMIT";
export const CLIENT_NAME = "CLIENT_NAME";
export const PROJ_NAME = "PROJ_NAME";
export const PROJ_CREATE = "PROJ_CREATE";
export const PROJ_DELETE = "PROJ_DELETE";
export const USER_CREATE = "USER_CREATE";
export const USER_EXIST = "USER_EXIST";
export const EMPTY_NAME = "EMPTY_NAME";
export const FIRST_NAME = "FIRST_NAME";
export const LAST_NAME = "LAST_NAME";
export const EMAIL = "EMAIL";
export const ALL = "ALL";
export const NOT_VALID = "NOT_VALID";
export const CHANGE_PASSW = "CHANGE_PASSW";
export const NOTIFICATION_EXIST = "NOTIFICATION_EXIST";

const cookies = new Cookies();

export const RECEIVE_PROJECTS = "RECEIVE_PROJECTS";
export const REQUEST_PROJECTS = "REQUEST_PROJECTS";
export const FETCH_PROJECTS_FAILURE = "FETCH_PROJECTS_FAILURE";

export const RECEIVE_USERS = "RECEIVE_USERS";
export const REQUEST_USERS = "REQUEST_USERS";

export const SET_LOGIN_PENDING = "SET_LOGIN_PENDING";
export const LOGIN_SUCCESS = "SET_LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const RECEIVE_ROLES = "RECEIVE_ROLES";
export const RECEIVE_USER_DATA = "RECEIVE_USER_DATA";

export const RECEIVE_NOTIFIC = "RECEIVE_NOTIFIC";

export function login(data) {
  return dispatch => {
    dispatch({type: SET_LOGIN_PENDING});
    authentication(data)
      .then(response => {
        dispatch(loginSuccess(response.data));
      })
      .catch(error => {
        dispatch(loginFailure("Invalid."));
      });
  };
}

function loginSuccess(data) {
  cookies.set("token", data.token);

  return {
    type: LOGIN_SUCCESS,
    payload: data.user
  };
}

function loginFailure(errorMessage) {
  openNotificationWithIcon("error", "Login failed")
  return {
    type: LOGIN_FAILURE,
    errorMessage
  };
}

export function logoutHandler() {
  return dispatch => {
    dispatch(logoutSuccess());
  };
}

function logoutSuccess() {
  cookies.remove("token");

  return {
    type: LOGOUT,
    payload: {}
  };
}

export function fetchRole() {
  return dispatch => {
    getRoles().then(data => {
      dispatch(receiveRole(data));
    });
  };
}

function receiveRole(json) {
  return {
    type: RECEIVE_ROLES,
    payload: json.data,
    message: "Roles were loaded"
  };
}

export function fetchUserData() {
  return dispatch => {
    getOneUser()
      .then(response => {
        dispatch(receiveUserData(response.data));
      })
      .catch(error => {
        switch (error.response.status) {
          case 404:
            console.log("No user with that email.");
            break;
          case 401:
            console.log("Invalid user.");
            cookies.remove("token");
            break;
          default:
            console.log("There was an error loggin in");
            return;
        }
      });
  };
}

function receiveUserData(data) {
  return {
    type: RECEIVE_USER_DATA,
    payload: data
  };
}

export function fetchNotific() {
  return dispatch => {
    getNotification().then(data => {
      dispatch(receiveNotific(data));
    });
  };
}

function receiveNotific(json) {
  return {
    type: RECEIVE_NOTIFIC,
    payload: json.data,
    message: "Notifications were loaded"
  };
}