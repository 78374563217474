import React from "react";

export default function TableHeader(props) {
    return (
        <tr className={props.class}>
            {props.columns.map((element, i) => {
                return (
                    <th key={i}>
                        <span className="ant-table-header-column">
                            <div>
                                <span className="ant-table-column-title">
                                    {element}
                                </span>
                            </div>
                        </span>
                    </th>
                )
            })}
        </tr>
    );
}
