import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { logoutHandler } from "../actions/actions";

class LogoutPage extends Component {
  componentWillMount() {
    this.props.logoutHandler();
    this.props.history.replace("/login");
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logoutHandler: () => dispatch(logoutHandler())
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(LogoutPage)
);
