import React from "react";
import Button from "./Button";
import { Modal } from "antd";

export function ModalCustom (props) {
  return (
    <Modal
      title={props.title}
      visible={props.visible}
      onCancel={props.toggleModal}
      footer={[
        <Button
          key="1"
          action={() => {
            props.handleSave();
            props.toggleModal();
          }}
          value={"Save"}
        />,
        <Button key="2" action={props.toggleModal} value={"Close"} />
      ]}
    >
      {props.context}
    </Modal>
  );
}