import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

function handleDates(startDate, endDate) {
  return {
    startDate,
    endDate
  };
}
export function handlePrevious(startDate, endDate, range) {
  const dates = handleDates(
    moment(startDate)
      .subtract(1, range)
      .startOf(range),
    moment(endDate)
      .subtract(1, range)
      .endOf(range)
  );
  return dates;
}

export function handleNext(startDate, endDate, range) {
  const dates = handleDates(
    moment(startDate)
      .add(1, range)
      .startOf(range),
    moment(endDate)
      .add(1, range)
      .endOf(range)
  );
  return dates;
}
