import React from "react";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

export function TableHeader(props) {
  return (
    <tr>
      <th>
        <span className="ant-table-header-column">
          <div>
            <span className="ant-table-column-title">
              Project
              </span>
          </div>
        </span>
      </th>
      {props.week.map((timeItem, index) => {
        return (
          <th key={index}>
            <span className="ant-table-header-column">
              <div>
                <span className="ant-table-column-title">
                  {moment(timeItem).format("dd, DD MMM")}
                </span>
              </div>
            </span>
          </th>
        );
      })}
      <th>Total</th>
      <th />
    </tr>
  );
}
