import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class Tab extends Component {
  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    let { path } = this.props.match;
    const { id } = this.props.match.params;

    const {
      onClick,
      props: { activeTab, label }
    } = this;

    let className = " ant-tabs-tab";

    if (activeTab === label) {
      className += " ant-tabs-tab-active ant-tabs-tab";
    }

    if (id) {
      let newPath = path.slice(0, -3);
      path = newPath + id;
    }

    return (
      <Link className={className} onClick={onClick} to={`${path}#${label}`}>
        {this.capitalizeFirstLetter(label)}
      </Link>
    );
  }
}

export default withRouter(Tab);
