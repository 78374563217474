import React, { PureComponent } from "react";
import Moment from "moment";
import { extendMoment } from "moment-range";
import {
  submitLogTime,
  deleteLogTime,
  fetchLogTimeData,
  sendNotification
} from "../../services/APIsersices";
import { convertProjectResponseForLogTime } from "../../helpers/convertResponse";
import Loader from "../../components/Loader";
import ButtonCustom from "../../components/Button";
import HeaderButtons from "../../components/HeaderButtons";
import { handlePrevious, handleNext } from "../../helpers/handleDays";
import {
  fetchNotific
} from "../../actions/actions";
import {
  ModalCustom
} from "../../components/Modal";
import ModalForAddRow from "../../components/UserTime/ModalForAddRow";
import { TableHeader } from "../../components/UserTime/TableHeader";
import { optionsForTask, openNotificationWithIcon } from "../../helpers/helpers";
import { Card, Input, Tooltip, Icon } from "antd";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const moment = extendMoment(Moment);
const { TextArea } = Input;
class UserTime extends PureComponent {
  constructor(props) {
    super(props);
    const first = moment().startOf("isoWeek");
    const last = moment().endOf("isoWeek");
    this.selectedItem = {
      projectId: "",
      task: ""
    };
    this.dataForComment = {
      comment: "",
      date: "",
      project: ""
    };
    this.state = {
      startDate: first,
      endDate: last,
      week: this.enumerateDaysBetweenDates(first, last),
      isLoading: true,
      range: "isoWeek",
      btnForLogTime: true,
      selectedTask: "",
      showAddRow: false,
      showAddComment: false,
      showWarning: false,
      showBan: false,
      optionsForTask: optionsForTask
    };
  }

  componentDidMount() {
    const { startDate, endDate } = this.state;
    this.fetchData(startDate, endDate);
  }

  fetchData(startDate, endDate) {
    fetchLogTimeData(startDate, endDate)
      .then(response => {
        const proj = convertProjectResponseForLogTime(response);
        let editable;
        const now = new Date().toJSON().slice(0, 10);
        proj.forEach(i => {
          if (i.editable) {
            editable = new Date(i.editable).toJSON().slice(0, 10);
          }
        });
        if (editable === now) {
          this.setState({
            proj,
            isLoading: false,
            showWarning: false,
            editable: editable
          });
        } else {
          this.setState({
            proj,
            isLoading: false
          });
        }
      })
      .catch(error => console.log(error));
  }

  handleInputChange = (prj, key, val, name) => {
    const newArr = this.state.proj.map((item) => {
      if (item.projectId === prj.projectId && item.task === prj.task) {
        if (name === "comment") {
          if (item.logTimeMap[key]) {
            if (val && " " !== val) {
              item.logTimeMap[key].comment = val;
              this.dataForComment.comment = val;
            } else {
              item.logTimeMap[key].comment = "";
              this.dataForComment.comment = "";
            }
          } else {
            if (val && " " !== val) {
              item.logTimeMap[key] = { comment: val };
              this.dataForComment.comment = val;
            } else {
              item.logTimeMap[key] = { comment: "" };
              this.dataForComment.comment = "";
            }
          }
        } else {
          if (val <= 24 && val.match(/^\d*(\.\d{0,2})?$/)) {
            if (item.logTimeMap[key]) {
              item.logTimeMap[key].amount = val;
            } else {
              item.logTimeMap[key] = { amount: val };
            }
          }
        }
        return item;
      }
      return {
        ...item
      };
    });

    this.setState({
      proj: newArr
    });
  };

  handleSelectChange = (name, optionSelected) => {
    if (name === "projectId") {
      this.selectedItem.projectId = optionSelected;
      const projects = this.state.proj;
      const pr = projects.filter(x => x.projectId === optionSelected);
      const arrOfTask = [];

      pr.forEach(i => {
        if (i.task) {
          arrOfTask.push(i.task);
        }
      });
      if (arrOfTask.length) {
        const opt = this.state.optionsForTask.filter(
          el => !arrOfTask.includes(el.label)
        );
        this.setState({
          optionsForTask: opt
        });
      } else {
        this.setState({
          optionsForTask: optionsForTask
        });
      }
    } else {
      this.selectedItem.task = optionSelected;
    }
  };

  handleSubmit = () => {
    this.setState({
      isLoading: true
    });

    const data = [];
    this.state.proj.forEach(i => {
      if (Object.keys(i.logTimeMap).length !== 0) {
        data.push(i);
      }
    });

    submitLogTime(data)
      .then(response => {
        this.setState({ isLoading: false });
        openNotificationWithIcon("success", "Saved")
      })
      .catch(error => console.error("Error:", error));
  };

  deleteRow(proj) {
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    deleteLogTime(startDate, endDate, proj)
      .then(() => {
        this.fetchData(startDate, endDate);
      })
      .catch(error => console.log(error));
  }

  handleCurentWeek = () => {
    const first = moment().startOf("isoWeek");
    const last = moment().endOf("isoWeek");
    this.setState({
      startDate: first,
      endDate: last,
      week: this.enumerateDaysBetweenDates(first, last),
      showWarning: false,
      showBan: false,
      isLoading: true
    });
    this.fetchData(first, last);
  };

  handleDates = (startDate, endDate) => {
    return {
      startDate,
      endDate
    };
  };

  checkAvalibleToChange = query => {
    const now = moment().startOf("isoWeek"); //todays date
    const end =
      query === "prev"
        ? moment(
          moment(this.state.startDate)
            .subtract(2, this.state.range)
            .startOf(this.state.range)
        )
        : moment(
          moment(this.state.startDate)
            .subtract(this.state.range)
            .startOf(this.state.range)
        );

    const duration = moment.duration(now.diff(end));
    const days = Math.floor(duration.asDays()) ;

    if (days <= 21 || this.state.editable) {
      this.setState({
        showWarning: false,
        showBan: false
      });
    } else {
      this.setState({
        showWarning: true,
        showBan: false
      });
    }
    if (days >= 91) {
      this.setState({
        showBan: true,
        showWarning: false
      });
    }
  };

  handlePreviousWeek = () => {
    const dates = handlePrevious(
      this.state.week[0],
      this.state.week[0],
      this.state.range
    );
    this.setState({
      startDate: dates.startDate,
      endDate: dates.endDate,
      week: this.enumerateDaysBetweenDates(dates.startDate, dates.endDate),
      showWarning: false,
      isLoading: true
    });
    this.fetchData(dates.startDate, dates.endDate);
    this.checkAvalibleToChange("prev");
  };

  handleNextWeek = () => {
    const dates = handleNext(
      this.state.week[0],
      this.state.week[0],
      this.state.range
    );
    this.setState({
      startDate: dates.startDate,
      endDate: dates.endDate,
      week: this.enumerateDaysBetweenDates(dates.startDate, dates.endDate),
      showBan: false,
      isLoading: true
    });
    this.fetchData(dates.startDate, dates.endDate);
    this.checkAvalibleToChange("next");
  };

  enumerateDaysBetweenDates = (startDate, endDate) => {
    let now = moment(startDate);
    const week = [];
    while (now.isBefore(endDate) || now.isSame(endDate)) {
      week.push(now.format());
      now.add(1, "days");
    }
    return week;
  };

  toggleModalComment = (key, comment, project) => {
    this.dataForComment.comment = comment;
    this.dataForComment.date = key;
    this.dataForComment.project = project;

    this.setState({
      showAddComment: !this.state.showAddComment
    });
  };

  toggleModalRow = () => {
    this.setState({
      showAddRow: !this.state.showAddRow
    });
  };

  renderProject = (project, index) => {
    let total = 0;
    if (project.task) {
      return (
        <tr className="ant-table-row ant-table-row-level-0" key={index}>
          <td>
            {project.projectInfo[0].projectName}{" "}
            <small>({project.projectInfo[0].clientName})</small>
            <div>
              <small>{project.task}</small>
            </div>
          </td>
          <ModalCustom
            title="Add comment"
            visible={this.state.showAddComment}
            handleSave={this.handleSubmit}
            toggleModal={this.toggleModalComment}
            context={(
              <TextArea
                name="comment"
                value={this.dataForComment.comment || ""}
                onChange={e =>
                  this.handleInputChange(
                    this.dataForComment.project,
                    this.dataForComment.date,
                    e.target.value,
                    e.target.name
                  )
                }
              />)}
          />
          {this.state.week.map((key, index) => {
            const amount = project.logTimeMap[key]
              ? project.logTimeMap[key].amount
              : "";

            const comment = project.logTimeMap[key]
              ? project.logTimeMap[key].comment
              : "";
            if (amount && amount !== "") total += +amount;

            return (
              <td key={index}>
                <div className="time">
                  <Input
                    disabled={this.state.showWarning || this.state.showBan}
                    autoComplete="off"
                    name="amount"
                    value={amount || ""}
                    step=".10"
                    onChange={e =>
                      this.handleInputChange(
                        project,
                        key,
                        e.target.value,
                        e.target.name
                      )
                    }
                  />
                  <Tooltip title={comment || ""} placement="top">
                    <span
                      onClick={() =>
                        this.state.showWarning || this.state.showBan
                          ? null
                          : this.toggleModalComment(key, comment, project)
                      }
                    >
                      {comment ? (
                        <Icon type="message" />
                      ) : (
                          <Icon type="plus-circle" />
                        )}
                    </span>
                  </Tooltip>
                </div>
              </td>
            );
          })}
          <td>{Math.floor(+total * 100) / 100}</td>
          <td>
            {this.state.showWarning || this.state.showBan ? null : (
              <span onClick={() => this.deleteRow(project)}>
                <Icon type="delete" />
              </span>
            )}
          </td>
        </tr>
      );
    }
  };

  modalForRow = (showAddRow, proj, handleClose) => {
    const handleSaveRow = () => {
      if (this.selectedItem.projectId && this.selectedItem.task) {
        let projId = this.selectedItem.projectId;

        let task = this.selectedItem.task;

        const project = proj.find(x => x.projectId === projId);

        var copy = Object.assign({}, project);
        copy.task = task;
        copy.logTimeMap = {};

        const newArray = [...proj, copy];

        this.setState({
          proj: newArray
        });
      }
      this.selectedItem = {
        projectId: "",
        task: ""
      };
    };

    const result = [];
    const map = new Map();
    for (const item of proj) {
      if (!map.has(item.projectId)) {
        map.set(item.projectId, true);
        result.push({
          projectId: item.projectId,
          name: `${item.projectInfo[0].projectName} - ${item.projectInfo[0].clientName}`
        });
      }
    }

    const optionsForProject = result.map(project => {
      return { label: project.name, value: project.projectId };
    });

    return (
      <ModalForAddRow
        optionsForProject={optionsForProject}
        optionsForTask={this.state.optionsForTask}
        proj={proj}
        handleSelectChange={this.handleSelectChange.bind(this)}
        handleClose={handleClose}
        handleSaveRow={handleSaveRow.bind(this)}
        showAddRow={showAddRow}
      />
    );
  };

  copyPrevRow = () => {
    const dates = handlePrevious(
      this.state.week[0],
      this.state.week[0],
      this.state.range
    );

    fetchLogTimeData(dates.startDate, dates.endDate).then(response => {
      const pattern = convertProjectResponseForLogTime(response);
      this.setState({
        proj: pattern
      });
    });
  };

  sendNotifMessage = () => {
    const data = {
      date: new Date().toJSON().slice(0, 10),
      message: "Access to change quarter"
    };
    sendNotification(data)
      .then(response => {
        if (response.data.length) {
          openNotificationWithIcon("warning", "You already send request")
        }
      })
      .catch(error => console.log(error));
    this.props.fetchNotific();
  };

  showWarningText() {
    return (
      <div className="text-center">
        <p>
          <i>You have to get permission</i>
        </p>
        <ButtonCustom
          action={this.sendNotifMessage}
          value={"Send request"}
        />
      </div>
    );
  }

  showBanText() {
    return (
      <div className="text-center">
        <p>
          <i>You don't have permission</i>
        </p>
      </div>
    );
  }

  render() {
    const data = this.state.proj;

    if (!data || this.state.isLoading) {
      return <Loader />;
    } else {
      const dataWithLogget = data.filter(
        i => Object.keys(i.logTimeMap).length !== 0
      );

      return (
        <Card title="Time">
          <header className="log_time_header">
            <HeaderButtons
              disabled={this.state.showBan}
              btnForLogTime={this.state.btnForLogTime}
              handleNextWeek={this.handleNextWeek}
              handlePreviousWeek={this.handlePreviousWeek}
              handleCurentWeek={this.handleCurentWeek}
            />
          </header>
          <div className="ant-table-wrapper gx-table-responsive">
            <div className="ant-spin-nested-loading">
              <div className="ant-spin-container">
                <div className="ant-table ant-table-default ant-table-scroll-position-left log_time">
                  <div className="ant-table-content">
                    <div className="ant-table-body">
                      {this.modalForRow(
                        this.state.showAddRow,
                        data,
                        this.toggleModalRow
                      )}
                      <table>
                        <tbody className="ant-table-body">
                          {this.state.proj.map(this.renderProject)}
                        </tbody>
                        <thead className="ant-table-thead">
                          <TableHeader week={this.state.week} />
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.showWarning ? this.showWarningText() : null}
          {this.state.showBan ? this.showBanText() : null}
          {dataWithLogget.length ? null : this.state.showWarning ||
            this.state.showBan ? null : (
              <div>
                <div className="copy_row">
                  <span onClick={this.copyPrevRow}>
                    Copy over all rows from most recent timesheet
                </span>
                </div>
              </div>
            )}
          {this.state.showWarning || this.state.showBan ? null : (
            <footer>
              <ButtonCustom
                disabled={Object.keys(data).length === 0}
                action={this.toggleModalRow}
                value={
                  <span>
                    <Icon type="plus" /> Add row
                  </span>
                }
              />{" "}
              <ButtonCustom
                disabled={Object.keys(data).length === 0}
                action={this.handleSubmit}
                value={"Save"}
                class={"btn btn-primary"}
              />
            </footer>
          )}
        </Card>
      );
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchNotific: data => dispatch(fetchNotific(data))
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(UserTime)
);
