import React from "react";
import { dateOptions } from "../../../helpers/helpers";
import { Select } from 'antd';
import { renderCustomDate } from "../../../components/ReportPage/renderCustomDate";
import moment from "moment";

const Option = Select.Option;

export default function SearchDate(props) {

  const children = [];
  for (let i = 0; i < dateOptions.length; i++) {
    children.push(<Option key={dateOptions[i].value}>{dateOptions[i].label}</Option>);
  }

  return (
    <React.Fragment>
      <Select
        className="gx-mr-3 gx-mb-3 d-print-none"
        defaultValue="isoWeek"
        style={{ minWidth: '150px' }}
        onChange={(value) => props.getRangeOfDates(value)}
      >
        {children}
      </Select>

      {props.isOpenCustomDate
        ? renderCustomDate(
          props.startDate,
          moment(props.value_1,'DD-MM-YYYY'),
          props.endDate,
          moment(props.value_2,'DD-MM-YYYY'),
          props.handleDatePicker
        )
        : null}
    </React.Fragment>
  );
}
