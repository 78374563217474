import React, { PureComponent } from "react";
import NoLogtime from "../../../components/NoLogtime";
import Loader from "../../../components/Loader";
import TableHeader from "../../../components/ReportPage/TableHeader"
import fetchData from "../../../services/fetchData";
import { convertProjectResponseForReport } from "../../../helpers/convertResponse";
import { withRouter } from "react-router-dom";
import {
  BreadcrumbsItem
} from 'react-breadcrumbs-dynamic'
const url = "/api/users/logTime/reports";

class TaskContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { startDate, endDate, onlyActive, groupBy } = this.props;

    if (this.props.match.params.id) {
      this.props.onInfoEnter(this.props.match.params.id);
      this.fetchTaskData(
        startDate,
        endDate,
        onlyActive,
        groupBy,
        this.props.match.params.id
      );
    } else {
      this.fetchTaskData(startDate, endDate, onlyActive, groupBy);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { startDate, endDate, onlyActive, groupBy } = nextProps;
    console.log(this.props.match);
    if (this.props.match.params.id) {
      this.fetchTaskData(
        startDate,
        endDate,
        onlyActive,
        groupBy,
        this.props.match.params.id
      );
    } else {
      this.fetchTaskData(startDate, endDate, onlyActive, groupBy);
    }
  }

  fetchTaskData = (startDate, endDate, onlyActive, groupBy, handle) => {
    fetchData(startDate, endDate, onlyActive, groupBy, url, handle)
      .then(response => {
        const proj = convertProjectResponseForReport(response, "task");
        this.setState({
          proj
        });
        sessionStorage.setItem("groupBy", groupBy);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  renderTask = (target, index) => {
    const logtimes = this.state.proj[target];
    let count = 0;
    let taskName;
    logtimes.forEach(log => {
      Object.keys(log.logTimeMap).forEach(time => {
        const date = time;
        const amount = log.logTimeMap[date].amount;
        count += amount;
        taskName = log.task;
      });
    });

    return (
      <tr className="ant-table-row ant-table-row-level-0" key={index}>
        <td>{taskName}</td>
        <td>{count}</td>
      </tr>
    );
  }

  render() {
    const data = this.state.proj;

    if (!data) {
      return <Loader />;
    } else {
      if (Object.keys(data).length === 0) {
        return <NoLogtime />;
      } else {
        return (
          <React.Fragment>
            {this.props.match.params.id ? <BreadcrumbsItem to='/report'>Time Report</BreadcrumbsItem>
              : null}
            <div className="ant-table-wrapper gx-table-responsive">
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table ant-table-default ant-table-scroll-position-left log_time">
                    <div className="ant-table-content">
                      <div className="ant-table-body">
                        <table id="table-to-xls">
                          <thead className="ant-table-thead">
                            <TableHeader columns={['Task', 'Time']} class="bg-prim" />
                          </thead>
                          <tbody className="ant-table-body">
                            {Object.keys(data).map(this.renderTask)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    }
  }
}

export default withRouter(TaskContent);
