import React, { Component } from "react";
import NoLogtime from "../../../components/NoLogtime";
import Loader from "../../../components/Loader";
import TableHeader from  "../../../components/ReportPage/TableHeader"
import fetchData from "../../../services/fetchData";
import { getProjects } from "../../../services/APIsersices";
import {
  convertProjectResponseForReport,
  convertProjectResponse
} from "../../../helpers/convertResponse";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
const url = "/api/users/logTime/reports";

class ProjectContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { startDate, endDate, onlyActive, groupBy } = this.props;
    if (this.props.match.params.id) {
      this.props.onInfoEnter(this.props.match.params.id);
      this.fetcProjecthData(
        startDate,
        endDate,
        onlyActive,
        groupBy,
        this.props.match.params.id
      )
    } else {
      this.fetcProjecthData(startDate, endDate, onlyActive, groupBy);
      this.fetcAllProjData();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { startDate, endDate, onlyActive, groupBy } = nextProps;

    if (
      this.props.startDate !== nextProps.startDate ||
      this.props.endDate !== nextProps.endDate ||
      this.props.onlyActive !== nextProps.onlyActive ||
      this.props.match.params.id !== nextProps.match.params.id
    ) {
      this.fetcProjecthData(
        startDate,
        endDate,
        onlyActive,
        groupBy,
        nextProps.match.params.id
      );
    }
  }

  fetcProjecthData = (startDate, endDate, onlyActive, groupBy, handle) => {
    fetchData(startDate, endDate, onlyActive, groupBy, url, handle)
      .then(response => {
        const proj = convertProjectResponseForReport(response, "project");
        this.setState({
          proj
        });
        sessionStorage.setItem("groupBy", groupBy);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetcAllProjData = () => {
    getProjects()
      .then(response => {
        const projectsAll = convertProjectResponse(response);
        this.setState({ projectsAll });
      })
      .catch(error => {
        console.log("Error occured in componentWillMount");
        console.log(error);
      });
  }

  changeGroupBy = () => {
    sessionStorage.setItem("groupByOne", "project");
  }

  renderProject = (target, index) => {
    let logtimes;
    if (this.state.proj) {
      logtimes = this.state.proj[target];
    }
    let count = 0;
    let id;
    let projName;
    if (logtimes) {
      logtimes.forEach(log => {
        Object.keys(log.logTimeMap).forEach(time => {
          const date = time;
          const amount = log.logTimeMap[date].amount;
          count += amount;
          id = log.projectId;
          projName = log.projectName;
        });
      });
    }
    return (
      <tr className="ant-table-row ant-table-row-level-0" key={index}>
        {target === projName ? (
          <React.Fragment>
            <td>
              <Link to={`/report/project/${id}`}>{projName}</Link>
            </td>
            <td>
              <Link to={`/report/details/${id}`} title="Detailed report"> {count}</Link>
            </td>
          </React.Fragment>
        ) : (
            <React.Fragment>
              <td> {target}</td>
              <td>0</td>
            </React.Fragment>
          )}
      </tr>
    );
  }

  renderProjectPerId = (target, index) => {
    const logtimes = this.state.proj[target];
    let count = 0;
    let id;
    let projectName

    logtimes.forEach(log => {
      Object.keys(log.logTimeMap).forEach(time => {
        const date = time;
        const amount = log.logTimeMap[date].amount;
        count += amount;
        id = log.projectId
        projectName = log.projectName;
      })
    })
    return (
      <tr className="ant-table-row ant-table-row-level-0" key={index}>
        <td>
          <Link onClick={this.changeGroupBy} to={`/report/details/${this.props.match.params.id}/${id}`} title="Detailed report">{projectName}</Link>
        </td>
        <td>
          {count}
        </td>
      </tr>
    );
  }

  render() {
    let data;
    this.props.match.params.id
      ? (data = this.state.proj)
      : (data = this.state.projectsAll);

    if (!data) {
      return <Loader />;
    } else {
      if (Object.keys(data).length === 0) {
        return <NoLogtime />;
      } else {
        return (
          <React.Fragment>
            {this.props.match.params.id ? <BreadcrumbsItem key="report" to="/report">Time Report</BreadcrumbsItem> : null}
            <div className="ant-table-wrapper gx-table-responsive">
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table ant-table-default ant-table-scroll-position-left log_time">
                    <div className="ant-table-content">
                      <div className="ant-table-body">
                        <table id="table-to-xls">
                        <thead className="ant-table-thead">
                        <TableHeader columns={['Project', 'Time']} class="bg-prim" />
                        </thead>
                        <tbody className="ant-table-body">
                            {this.props.match.params.id
                              ? Object.keys(data).map(this.renderProjectPerId)
                              : Object.keys(data).map(this.renderProject)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    }
  }
}

export default withRouter(ProjectContent);
