import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

axios.interceptors.request.use(
  config => {

    const token = cookies.get("token");

    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  error => Promise.reject(error)
);

export default axios;
