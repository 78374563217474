import React, { Component } from "react";
import { Avatar, Popover } from "antd";
import { Link } from "react-router-dom";

class UserInfo extends Component {

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <Link to="/people/basicinfo">
          <li>
            My Profile
          </li>
        </Link>
        <Link to="/people/projects">
          <li>
            My Projects
          </li>
        </Link>
        <Link to="/logout">
          <li>
            Logout
          </li>
        </Link>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
        trigger="click">
        <Avatar src={this.props.sourse}
          className="gx-avatar gx-pointer ant-avatar-image" alt="" />
      </Popover>
    )
  }
}

export default UserInfo;
