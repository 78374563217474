import { RECEIVE_ROLES } from "../actions/actions.js";

const initialState = {
  roles: [],
  message: ""
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_ROLES:
      state = {
        ...state,
        roles: action.payload,
        message: action.message
      };
      break;
    default:
      return state;
  }
  return state;
};

export default rolesReducer;
