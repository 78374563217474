import React, { PureComponent } from "react";
import Loader from "../../../components/Loader";
import fetchData from "../../../services/fetchData";
import { convertProjectResponseForReport } from "../../../helpers/convertResponse";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { groupOptions, timePeriod } from "../../../helpers/helpers";
import { handlePrevious, handleNext } from "../../../helpers/handleDays";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Button from "../../../components/Button";
import HeaderButtons from "../../../components/HeaderButtons";
import "../../../stylesheets/printstyles.css";
import SearchDate from "./SearchDate";
import { withRouter } from "react-router-dom";
import PrintHeader from "../../../components/ReportPage/PrintHeader";
import ModalForSelectColumn from "../../../components/ReportPage/Modal.js";
import { Checkbox, Row, Select, Icon } from "antd";

const Option = Select.Option;

const moment = extendMoment(Moment);

const url = "/api/users/logTime/reports";

class DetailedReport extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(sessionStorage.getItem("startDate")),
      endDate: moment(sessionStorage.getItem("endDate")),
      range: "isoWeek",
      isOpen: false,
      isOpenCustomDate: false,
      onlyActive: false,
      groupBy: "project",
      showSelectColumn: false,
      optionForCheckbox: {
        date: true,
        "project Name": true,
        task: true,
        "user Name": true,
        amount: true,
        comment: true
      }
    };
  }

  setSession(name, value) {
    sessionStorage.setItem(name, value);
  }

  componentDidMount() {
    const { startDate, endDate, onlyActive, groupBy } = this.state;
    if (this.props.match.params.id) {
      this.fetcProjecthData(
        startDate,
        endDate,
        onlyActive,
        groupBy,
        this.props.match.params.id,
        sessionStorage.getItem("groupBy"),
        this.props.match.params.byOne,
        sessionStorage.getItem("groupByOne")
      );
    } else {
      this.fetcProjecthData(startDate, endDate, onlyActive, groupBy);
    }
  }

  fetcProjecthData(
    startDate,
    endDate,
    onlyActive,
    groupBy,
    handle,
    details,
    byOne,
    detailsByOne
  ) {
    fetchData(
      startDate,
      endDate,
      onlyActive,
      groupBy,
      url,
      handle,
      details,
      byOne,
      detailsByOne
    )
      .then(response => {
        const proj = convertProjectResponseForReport(response, groupBy);
        this.setState({
          proj
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  toggleChangeActive = () => {
    this.setState(
      prevState => ({
        onlyActive: !prevState.onlyActive
      }),
      () =>
        this.fetcProjecthData(
          this.state.startDate,
          this.state.endDate,
          this.state.onlyActive,
          this.state.groupBy,
          this.props.match.params.id,
          sessionStorage.getItem("groupBy"),
          this.props.match.params.byOne,
          sessionStorage.getItem("groupByOne")
        )
    );
  };

  renderProject = (target, index) => {
    const logtimes = this.state.proj[target];
    let count = 0;

    const handleLogTimeMap = () => {
      const logs = [];
      logtimes.forEach(log => {
        Object.keys(log.logTimeMap).forEach(time => {
          const date = time;
          const amount = log.logTimeMap[date].amount;
          const comment = log.logTimeMap[date].comment;

          count += amount;

          logs.push({
            date: moment(date).format("dd, DD MMM"),
            amount: amount,
            comment: comment,
            projectName: log.projectName,
            task: log.task,
            userName: log.userName
          });
        });
      });
      return logs;
    };

    let logTime = handleLogTimeMap();

    return (
      <tbody className="ant-table-body" key={index}>
        <tr className="ant-table-row ant-table-row-level-0 highlighted">
          <td colSpan={this.handleColSpan()}>{target}</td>
          <td className="text-right">{count}</td>
        </tr>

        {logTime.map((entry, i) => {
          return (
            <React.Fragment key={i}>
              <tr className="ant-table-row ant-table-row-level-0">
                {this.state.optionForCheckbox["date"] ? (
                  <td>{entry.date}</td>
                ) : null}
                {this.state.optionForCheckbox["project Name"] ? (
                  <td>{entry.projectName}</td>
                ) : null}
                {this.state.optionForCheckbox["task"] ? (
                  <td>{entry.task}</td>
                ) : null}
                {this.state.optionForCheckbox["user Name"] ? (
                  <td>{entry.userName}</td>
                ) : null}
                {this.state.optionForCheckbox["amount"] ? (
                  <td>{entry.amount}</td>
                ) : null}
                {this.state.optionForCheckbox["comment"] ? (
                  <td>{entry.comment}</td>
                ) : null}
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
    );
  };

  handleColSpan = () => {
    let colSpan = -1;
    Object.keys(this.state.optionForCheckbox).map(i => {
      if (this.state.optionForCheckbox[i]) {
        colSpan += 1;
      }
      return colSpan;
    });
    return colSpan;
  };

  handlePrevious = () => {
    const dates = handlePrevious(
      this.state.startDate,
      this.state.endDate,
      this.state.range
    );

    this.setState({
      startDate: dates.startDate,
      endDate: dates.endDate
    });
    const { startDate, endDate } = dates;

    this.fetcProjecthData(
      startDate,
      endDate,
      this.state.onlyActive,
      sessionStorage.getItem("groupBy"),
      this.props.match.params.id,
      sessionStorage.getItem("groupBy"),
      this.props.match.params.byOne,
      sessionStorage.getItem("groupByOne")
    );
    this.setSession("startDate", dates.startDate);
    this.setSession("endDate", dates.endDate);
  };

  handleNext = () => {
    const dates = handleNext(
      this.state.startDate,
      this.state.endDate,
      this.state.range
    );
    const { startDate, endDate } = dates;

    this.setState({
      startDate: dates.startDate,
      endDate: dates.endDate
    });

    this.fetcProjecthData(
      startDate,
      endDate,
      this.state.onlyActive,
      sessionStorage.getItem("groupBy"),
      this.props.match.params.id,
      sessionStorage.getItem("groupBy"),
      this.props.match.params.byOne,
      sessionStorage.getItem("groupByOne")
    );
    this.setSession("startDate", dates.startDate);
    this.setSession("endDate", dates.endDate);
  };

  getGroup(val) {
    const { startDate, endDate, onlyActive } = this.state;
    this.setState(
      {
        groupBy: val
      },
      this.fetcProjecthData(
        startDate,
        endDate,
        onlyActive,
        val,
        this.props.match.params.id,
        sessionStorage.getItem("groupBy"),
        this.props.match.params.byOne,
        sessionStorage.getItem("groupByOne")
      )
    );
  }

  hendleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  handleDatePicker = (dateName, dateValue) => {
    let { startDate, endDate } = this.state;
    if (dateName === "startDateTime") {
      startDate = dateValue;
    } else {
      endDate = moment(dateValue) > moment(startDate) ? dateValue : startDate;
    }
    this.setState(
      {
        startDate: moment(startDate),
        endDate: moment(endDate)
      },
      () =>
        this.fetcProjecthData(
          this.state.startDate,
          this.state.endDate,
          this.state.onlyActive,
          this.state.groupBy,
          this.props.match.params.id,
          sessionStorage.getItem("groupBy"),
          this.props.match.params.byOne,
          sessionStorage.getItem("groupByOne")
        )
    );
  };

  getRangeOfDates = val => {
    if (val === "custom") {
      this.setState({
        isOpenCustomDate: true
      });
    } else {
      const dates = timePeriod[val];
      this.setState(
        {
          startDate: dates.startDate,
          endDate: dates.endDate,
          range: val,
          isOpenCustomDate: false
        },
        () =>
          this.fetcProjecthData(
            this.state.startDate,
            this.state.endDate,
            this.state.onlyActive,
            this.state.groupBy,
            this.props.match.params.id,
            sessionStorage.getItem("groupBy"),
            this.props.match.params.byOne,
            sessionStorage.getItem("groupByOne")
          )
      );
      sessionStorage.setItem("startDate", dates.startDate);
      sessionStorage.setItem("endDate", dates.endDate);
    }
  };

  toggleModal = () => {
    this.setState({
      showSelectColumn: !this.state.showSelectColumn
    });
  };

  handleChangeCheckbox = name => event => {
    this.setState({
      optionForCheckbox: {
        ...this.state.optionForCheckbox,
        [name]: event.target.checked
      }
    });
  };

  renderColumnHeader = text => {
    return (
      <th>
        <span className="ant-table-header-column">
          <div>
            <span className="ant-table-column-title">{text}</span>
          </div>
        </span>
      </th>
    );
  };

  render() {
    const customStyles = {
      option: provided => ({
        ...provided,
        color: "black"
      })
    };

    const first = moment(this.state.startDate).format("DD MMM");
    const last = moment(this.state.endDate).format("DD MMM");
    const fromYear = moment(this.state.startDate).format("YYYY");
    const toYear = moment(this.state.endDate).format("YYYY");

    const data = this.state.proj;

    const children = [];
    for (let i = 0; i < groupOptions.length; i++) {
      children.push(
        <Option key={groupOptions[i].value}>{groupOptions[i].label}</Option>
      );
    }

    if (!data) {
      return <Loader />;
    } else {
      return (
        <main>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{ marginBottom: "15px" }}
            className="d-print-none"
          >
            <p>
              <strong>Detailed Time Report:</strong> {first} {fromYear} - {last}{" "}
              {toYear}
            </p>
            <Button
              action={this.hendleOpen}
              class={"btn btn-light d-print-none"}
              value="Change Filters"
            />
          </Row>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{ marginBottom: "15px" }}
            className="d-print-none"
          >
            {this.state.isOpen ? (
              <React.Fragment>
                <div>
                  <HeaderButtons
                    handleNext={this.handleNext}
                    handlePrevious={this.handlePrevious}
                  />
                </div>
                <SearchDate
                  styles={customStyles}
                  getRangeOfDates={this.getRangeOfDates}
                  startDate={
                    this.state.startDate ? new Date(this.state.startDate) : null
                  }
                  value_1={moment(this.state.startDate).format("DD-MM-YYYY")}
                  endDate={
                    this.state.endDate ? new Date(this.state.endDate) : null
                  }
                  value_2={moment(this.state.endDate).format("DD-MM-YYYY")}
                  handleDatePicker={this.handleDatePicker}
                  isOpenCustomDate={this.state.isOpenCustomDate}
                />
              </React.Fragment>
            ) : null}
          </Row>
          <Row type="flex" justify="space-between" align="middle" className="d-print-none">
            <Select
              className="gx-mr-3 gx-mb-3"
              defaultValue="project"
              style={{ minWidth: "150px" }}
              onChange={value => this.getGroup(value)}
            >
              {children}
            </Select>
          </Row>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="d-print-none"
          >
            {this.props.match.params.byOne ? (
              <div className="custom-control"></div>
            ) : (
              <Checkbox
                name="onlyActive"
                onChange={this.toggleChangeActive}
                checked={this.state.onlyActive}
              >
                {" "}
                Active projects only
              </Checkbox>
            )}
            <div>
              <Button
                action={this.toggleModal}
                class={"btn btn-light"}
                value="Select column"
              />
              <Button
                action={() => window.print()}
                class={"btn btn-light btn_print"}
                value={<Icon type="printer" />}
              />
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="ant-btn"
                table="table-to-xls"
                filename={`Detailed report ${first} ${fromYear} - ${last} ${toYear}`}
                sheet="tablexls"
                buttonText="Download"
              />
            </div>
          </Row>
          <div>
            <PrintHeader
              startDate={first}
              endDate={last}
              toYear={toYear}
              fromYear={fromYear}
              groupBy={this.state.groupBy}
            />
            <div className="ant-table-wrapper gx-table-responsive">
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table ant-table-default ant-table-scroll-position-left log_time">
                    <div className="ant-table-content">
                      <div className="ant-table-body">
                        <table id="table-to-xls">
                          <thead className="ant-table-thead">
                            <tr>
                              {this.state.optionForCheckbox["date"]
                                ? this.renderColumnHeader("Date")
                                : null}
                              {this.state.optionForCheckbox["project Name"]
                                ? this.renderColumnHeader("Project")
                                : null}
                              {this.state.optionForCheckbox["task"]
                                ? this.renderColumnHeader("Task")
                                : null}
                              {this.state.optionForCheckbox["user Name"]
                                ? this.renderColumnHeader("Person")
                                : null}
                              {this.state.optionForCheckbox["amount"]
                                ? this.renderColumnHeader("Hours")
                                : null}
                              {this.state.optionForCheckbox["comment"]
                                ? this.renderColumnHeader("Comment")
                                : null}
                            </tr>
                          </thead>
                          {Object.keys(data).map(this.renderProject)}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalForSelectColumn
            handleChange={this.handleChangeCheckbox}
            showSelectColumn={this.state.showSelectColumn}
            optionForCheckbox={this.state.optionForCheckbox}
            toggleModal={this.toggleModal}
          />
        </main>
      );
    }
  }
}

export default withRouter(DetailedReport);
