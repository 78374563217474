import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import {ThroughProvider} from 'react-through'
import App from "./App.js";

import configureStore from "./store/configureStore";

const initialState = {
  fetching: false,
  fetched: false,
  users: [],
  roles: [],
  error: null
};

const store = configureStore(initialState);

render(
  <Provider store={store}>
  <ThroughProvider>
        <App />
  </ThroughProvider>
  </Provider>,
  document.getElementById("root")
);
