import React, { Component } from "react";
import { Button, Form, Input } from "antd";
import { resetPassword } from "../../services/APIsersices";
import { openNotificationWithIcon } from "../../helpers/helpers";
import logoWhite from '../../assets/images/logo_white.png'

const FormItem = Form.Item;

class ResetPasswPage extends Component {

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        resetPassword(values)
        .then(
          () => {
           openNotificationWithIcon("success", "Password successfully changed");
           this.props.history.push("/login");
          }
        )
        .catch(error =>
          openNotificationWithIcon("error", "Can't change password")
        );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-login-container">
        <div className="gx-login-content">
          <div className="gx-login-header">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo">
                <img
                  src={logoWhite}
                  alt="Elitex"
                  title="Elitex"
                />
              </div>
            </div>
          </div>
          <div className="gx-mb-4">
            <h2>Forgot Your Password ?</h2>
            <p>
              Enter your email address and we will send you a link with your new
              password
            </p>
          </div>

          <Form
            layout="vertical"
            onSubmit={this.handleSubmit}
            className="gx-login-form gx-form-row0"
          >
            <FormItem>
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "The input is not valid E-mail!"
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!"
                  }
                ]
              })(
                <Input
                  type="email"
                  placeholder="E-Mail Address"
                />
              )}
            </FormItem>
            <FormItem className="gx-text-center">
              <Button type="primary" htmlType="submit">
                Send password to email
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default Form.create()(ResetPasswPage);
