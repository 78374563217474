import React from 'react';
import { DatePicker, Row } from 'antd'

export function renderCustomDate(selected_1, value_1, selected_2, value_2, handleDatePicker) {
    return (
        <Row type="flex" justify="center" align="middle" className="d-print-none"
            style={{ marginBottom: "15px" }}>
            <DatePicker className="gx-mb-3 gx-w-100"
                selected={selected_1 ? new Date(selected_1) : null}
                format="DD-MM-YYYY"
                value={value_1}
                placeholder="Start"
                onChange={date => handleDatePicker("startDateTime", date)}
            />
            <DatePicker className="gx-mb-3 gx-w-100"
                selected={selected_2 ? new Date(selected_2) : null}
                format="DD-MM-YYYY"
                value={value_2}
                placeholder="End"
                onChange={date => handleDatePicker("endDateTime", date)}
            />
        </Row>
    )
}