import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import Button from "../../components/Button";
import { fetchNotific } from "../../actions/actions";
import { getNotification, sendReply } from "../../services/APIsersices";
import { convertNotification } from "../../helpers/convertResponse";
import { Card, Table } from "antd";

const columns = [
  {
    title: "Message",
    dataIndex: "message",
    key: "message"
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action"
  }
];

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    getNotification()
      .catch(error => {
        console.log("Failed");
      })
      .then(response => {
        const messages = convertNotification(response);
        this.setState({ messages });
      });
  }

  addAccess = (user, date, answer) => {
    const data = {
      answer,
      id: user,
      date
    };
    sendReply(data)
      .catch(error => {
        console.log("Failed");
      })
      .then(() => {
        getNotification()
          .catch(error => {
            console.log("Failed");
          })
          .then(response => {
            const messages = convertNotification(response);
            this.setState({ messages });
            this.props.fetchNotific();
          });
      });
  };

  render() {
    const data = this.state.messages;

    if (!data) {
      return <Loader />;
    } else {
      const dataSource = []; 
      Object.keys(data).length !== 0  && data.forEach((msg, index) => {
        dataSource.push({
          key: index,
          message: `${msg.userName} asked to make changes`,
          action: (
            <React.Fragment>
              <Button
                action={() => this.addAccess(msg.userId, msg.date, true)}
                value={"Confirm"}
              />{' '}
              <Button
                action={() => this.addAccess(msg.userId, msg.date, false)}
                value={"Cancel"}
              />
            </React.Fragment>
          )
        });
      });

      return (
        <Card title="Notifications">
          {Object.keys(data).length !== 0 ? (
            <Table
              className="gx-table-responsive"
              columns={columns}
              dataSource={dataSource}
            />
          ) : (
            <p>You don't have any notifications.</p>
          )}
        </Card>
      );
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchNotific: data => dispatch(fetchNotific(data))
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(Notification)
);
