import React, { PureComponent } from "react";
import { Route, NavLink, Link } from "react-router-dom";
import { Switch } from "react-router";
import Moment from "moment";
import { extendMoment } from "moment-range";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { dateOptions, timePeriod } from "../../helpers/helpers";
import Tabs from "../../components/Tabs/Tabs";
import HeaderButtons from "../../components/HeaderButtons";
import ProjectContent from "./Content/ProjectContent";
import UserContent from "./Content/UserContent";
import ClientContent from "./Content/ClientContent";
import TaskContent from "./Content/TaskContent";
import DetailedReport from "./Detailed/DetailedReport";
import { handlePrevious, handleNext } from "../../helpers/handleDays";
import Button from "../../components/Button";
import { renderCustomDate } from "../../components/ReportPage/renderCustomDate";
import PrintHeader from "../../components/ReportPage/PrintHeader";
import "../../stylesheets/printstyles.css";
import {
  Breadcrumbs,
  BreadcrumbsItem
} from 'react-breadcrumbs-dynamic'

import { Card, Icon, Checkbox, Row, Select } from "antd";

const Option = Select.Option;
const moment = extendMoment(Moment);

class ReportPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().startOf("isoWeek"),
      endDate: moment().endOf("isoWeek"),
      range: "isoWeek",
      isOpenCustom: false,
      onlyActive: false,
      queryForDetails: ""
    }
    this.setSession("startDate", this.state.startDate)
    this.setSession("endDate", this.state.endDate)
  }

  handleInfo = (id) => {
    this.setState({
      queryForDetails: id
    });
  };

  setSession(name, value) {
    sessionStorage.setItem(name, value);
  }

  toggleChangeActive = () => {
    this.setState(prevState => ({
      onlyActive: !prevState.onlyActive
    }));
  };

  handlePrevious = () => {
    const dates = handlePrevious(
      this.state.startDate,
      this.state.endDate,
      this.state.range
    );

    this.setState({
      startDate: dates.startDate,
      endDate: dates.endDate
    });

    this.setSession("startDate", dates.startDate)
    this.setSession("endDate", dates.endDate)
  };

  handleNext = () => {
    const dates = handleNext(
      this.state.startDate,
      this.state.endDate,
      this.state.range
    );

    this.setState({
      startDate: dates.startDate,
      endDate: dates.endDate
    });

    this.setSession("startDate", dates.startDate)
    this.setSession("endDate", dates.endDate)
  };

  handleDatePicker = (dateName, dateValue) => {
    let { startDate, endDate } = this.state;
    if (dateName === "startDateTime") {
      startDate = dateValue;
    } else {
      endDate = moment(dateValue) > moment(startDate) ? dateValue : startDate
    }
    this.setState({
      startDate: moment(startDate),
      endDate: moment(endDate)
    });
    this.setSession("startDate", moment(startDate))
    this.setSession("endDate", moment(endDate))
  }

  getRangeOfDates(val) {
    if (val === "custom") {
      this.setState({
        isOpenCustom: true
      });
    } else {
      const dates = timePeriod[val];
      this.setState({
        startDate: dates.startDate,
        endDate: dates.endDate,
        range: val,
        isOpenCustom: false
      });
      this.setSession("startDate", dates.startDate)
      this.setSession("endDate", dates.endDate)
    }
  }

  handleOpenCustom = () => {
    if (this.state.isOpenCustom) {
      return renderCustomDate(
        this.state.startDate ? new Date(this.state.startDate) : null,
        this.state.startDate,
        this.state.endDate ? new Date(this.state.endDate) : null,
        this.state.endDate,
        this.handleDatePicker
      );
    }
  };

  header = (query) => {
    const first = moment(this.state.startDate).format("DD MMM");
    const last = moment(this.state.endDate).format("DD MMM");
    const fromYear = moment(this.state.startDate).format("YYYY");
    const toYear = moment(this.state.endDate).format("YYYY");

    const children = [];
    for (let i = 0; i < dateOptions.length; i++) {
      children.push(<Option key={dateOptions[i].value}>{dateOptions[i].label}</Option>);
    }

    return (
      <div>
        <Row type="flex" justify="space-between" align="middle" className="d-print-none">
          <HeaderButtons
            handleNext={this.handleNext}
            handlePrevious={this.handlePrevious}
          />
          <p>
            {first} {fromYear} - {last} {toYear}
          </p>
          <Select
            className="gx-mr-3 gx-mb-3"
            defaultValue="isoWeek"
            style={{ minWidth: '150px' }}
            onChange={(value) => this.getRangeOfDates(value)}
          >
            {children}
          </Select>
        </Row>
        {this.handleOpenCustom()}
        <Row type="flex" justify="space-between" align="middle" className="d-print-none">
          <div>
            <Checkbox
              name="onlyActive"
              onChange={this.toggleChangeActive}
              checked={this.state.onlyActive}
            > Active projects only</Checkbox>
          </div>
          <div>
            <Link
              to={
                query
                  ? `/report/details/${this.state.queryForDetails}`
                  : `/report/details/`
              }
              className="link"
            >
              {" "}
              Detailed report
          </Link>
            <Button
              action={() => window.print()}
              class={"ant-btn btn_print"}
              value={<Icon type="printer" />}
            />
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="ant-btn"
              table="table-to-xls"
              filename={ `Report ${first} ${fromYear} - ${last} ${toYear}`}
              sheet="tablexls"
              buttonText="Download"
            />
          </div>
        </Row>
        <PrintHeader startDate={first} endDate={last} toYear={toYear} fromYear={fromYear} />
        <nav>
          <div className="ant-breadcrumb">
            <Breadcrumbs separator={<b> / </b>}
              item={NavLink} />
          </div>
        </nav>
      </div>
    );
  };

  renderReport = () => {
    return (
      <div>
        {this.header(false)}
        <Tabs>
          <div label="clients">
            <ClientContent
              groupBy={"client"}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onlyActive={this.state.onlyActive}
            />
          </div>
          <div label="projects">
            <ProjectContent
              groupBy={"project"}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onlyActive={this.state.onlyActive}
            />
          </div>
          <div label="users">
            <UserContent
              groupBy={"person"}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onlyActive={this.state.onlyActive}
            />
          </div>
          <div label="tasks">
            <TaskContent
              groupBy={"task"}
              onInfoEnter={this.handleInfo}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onlyActive={this.state.onlyActive}
            />
          </div>
        </Tabs>
      </div>
    );
  };

  renderReportPerClient = () => {
    return (
      <div>
        {this.header(true)}
        <BreadcrumbsItem to='/report#clients'>Client</BreadcrumbsItem>
        <Tabs>
          <div label="projects">
            <ProjectContent
              groupBy={"client"}
              onInfoEnter={this.handleInfo}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onlyActive={this.state.onlyActive}
            />
          </div>
          <div label="team">
            <UserContent
              groupBy={"client"}
              onInfoEnter={this.handleInfo}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onlyActive={this.state.onlyActive}
            />
          </div>
          <div label="tasks">
            <TaskContent
              groupBy={"client"}
              onInfoEnter={this.handleInfo}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onlyActive={this.state.onlyActive}
            />
          </div>
        </Tabs>
      </div>
    );
  };

  renderReportPerUser = () => {
    return (
      <div>
        <BreadcrumbsItem to='/report#users'>User</BreadcrumbsItem>
        {this.header(true)}
        <Tabs>
          <div label="projects">
            <ProjectContent
              groupBy={"person"}
              onInfoEnter={this.handleInfo}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onlyActive={this.state.onlyActive}
            />
          </div>
          <div label="tasks">
            <TaskContent
              groupBy={"person"}
              onInfoEnter={this.handleInfo}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onlyActive={this.state.onlyActive}
            />
          </div>
        </Tabs>
      </div>
    );
  };

  renderReportPerProj = () => {
    return (
      <div>
        <BreadcrumbsItem to='/report#projects'>Project</BreadcrumbsItem>
        {this.header(true)}
        <Tabs>
          <div label="team">
            <UserContent
              groupBy={"project"}
              onInfoEnter={this.handleInfo}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onlyActive={this.state.onlyActive}
            />
          </div>
          <div label="tasks">
            <TaskContent
              groupBy={"project"}
              onInfoEnter={this.handleInfo}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onlyActive={this.state.onlyActive}
            />
          </div>
        </Tabs>
      </div>
    );
  };

  renderDetailed = () => {
    return <DetailedReport />;
  };

  renderDetailedPerQuery = () => {
    return <DetailedReport />;
  };

  render() {
    const { path } = this.props.match;
    return (
      <Card title="Report">
        <Route exact path={`${path}`} render={this.renderReport} />
        <Route path={`${path}/user/:id`} render={this.renderReportPerUser} />
        <Route
          path={`${path}/client/:id`}
          render={this.renderReportPerClient}
        />
        <Route path={`${path}/project/:id`} render={this.renderReportPerProj} />
        <Switch>
          <Route
            path={`${path}/details/:id?/:byOne?`}
            render={this.renderDetailedPerQuery}
          />
          <Route path={`${path}/details`} render={this.renderDetailed} />
        </Switch>
      </Card>
    );
  }
}

export default ReportPage;
