import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import authenticationReducer from "../reducers/authenticationReducer";
import rolesReducer from "../reducers/rolesReducer";
import notificationReducer from "../reducers/notificationReducer";

const loggerMiddleware = createLogger();

export default function configureStore(initialState) {
  return createStore(
    combineReducers({
      authenticationReducer,
      rolesReducer,
      notificationReducer
    }),
    applyMiddleware(thunkMiddleware, loggerMiddleware)
  )
}