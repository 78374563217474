import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { login } from "../../actions/actions";
import { withRouter } from "react-router";

import { Button, Form, Input } from "antd";
import logoWhite from '../../assets/images/logo_white.png'

const FormItem = Form.Item;

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.loggedIn) {
      this.props.history.push("/");
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const data = {
      email: this.state.email.trim(),
      password: this.state.password
    };
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.login(data);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="gx-login-container">
        <div className="gx-login-content">
          <div className="gx-login-header">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo">
                <img
                  src={logoWhite}
                  alt="Elitex"
                  title="Elitex"
                />
              </div>
            </div>
          </div>
          <div className="gx-login-header gx-text-center">
            <h1 className="gx-login-title">Sign In</h1>
          </div>
          <Form
            onSubmit={this.handleSubmit}
            className="gx-signin-form gx-form-row0"
          >
            <FormItem>
              {getFieldDecorator("email", {
                initialValue: this.state.email,
                rules: [
                  {
                    required: true,
                    type: "email",
                    message: "The input is not valid E-mail!"
                  }
                ]
              })(
                <Input
                  placeholder="Email"
                  name="email"
                  onChange={this.handleChange}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("password", {
                initialValue: this.state.password,
                rules: [
                  { required: true, message: "Please input your Password!" }
                ]
              })(
                <Input
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={this.handleChange}
                />
              )}
            </FormItem>
            <FormItem>
              <Link className="gx-login-form-forgot" to="/reset">
                Forgot password
              </Link>
            </FormItem>
            <FormItem className="gx-text-center">
              <Button
                type="primary"
                disabled={this.props.isLoginPending}
                className="gx-mb-0"
                htmlType="submit"
              >
                Log in
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.authenticationReducer.loggedIn,
    isLoginPending: state.authenticationReducer.isLoginPending
  };
};
const mapDispatchToProps = dispatch => {
  return {
    login: data => dispatch(login(data))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(LoginPage))
);
