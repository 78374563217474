import React from "react";
import { Link } from "react-router-dom";

const NoLogtime = () => {
  return (
    <div className="no_log_time">
      You have no hours recorded for this time period. Track your time under
      <Link to="/"> Timesheets.</Link>
    </div>
  );
};

export default NoLogtime;
