import React, { Component } from "react";
import Loader from "../../components/Loader";
import { formItemLayout } from "../../helpers/helpers";
import { Card, Button, Input, Form, Upload, Icon } from "antd";

const FormItem = Form.Item;

class BasicInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: {
        value: this.props.user.firstName,
        placeholder: this.props.user.firstName
      },
      lastName: {
        value: this.props.user.lastName,
        placeholder: this.props.user.lastName
      }
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      state.firstName.placeholder !== props.user.firstName ||
      state.lastName.placeholder !== props.user.lastName
    ) {
      return {
        firstName: {
          placeholder: props.user.firstName,
          value: props.user.firstName
        },
        lastName: {
          placeholder: props.user.lastName,
          value: props.user.lastName
        },
        path: ""
      };
    }
    return null;
  }

  fileChangedHandler = event => {
    this.setState({
      path: event.file.originFileObj
    });
  };

  handleUserInfo = () => {
    const firstName = this.state.firstName.value;
    const lastName = this.state.lastName.value;

    if (!firstName && !lastName) {
      return <Loader />;
    } else {
      const { getFieldDecorator } = this.props.form;

      return (
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.props.handleSubmit(firstName, lastName, this.state.path);
          }}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem {...formItemLayout} label="First Name">
            {getFieldDecorator("firstName", {
              initialValue: this.state.firstName["value"]
            })(
              <Input
                name="firstName"
                onChange={event => {
                  const firstName = this.state.firstName;
                  firstName.value = event.target.value;
                  this.setState({ firstName });
                }}
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Last Name">
            {getFieldDecorator("lastName", {
              initialValue: this.state.lastName["value"]
            })(
              <Input
                name="lastName"
                onChange={event => {
                  const lastName = this.state.lastName;
                  lastName.value = event.target.value;
                  this.setState({ lastName });
                }}
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Work Email">
            {getFieldDecorator("Work Email", {
              initialValue: this.props.user.email || ""
            })(<Input name="Work Email" disabled={true} />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Upload photo">
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              onChange={this.fileChangedHandler}
            >
              <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
              </div>
            </Upload>
          </FormItem>
          <FormItem>
            <Button type="primary" className="gx-mb-0" htmlType="submit">
              Update Info
            </Button>
          </FormItem>
        </Form>
      );
    }
  };

  render() {
    return (
      <Card title="Your Basic Info">
        {this.handleUserInfo(this.props.user)}
      </Card>
    );
  }
}
export default Form.create()(BasicInfo);
