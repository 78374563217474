import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

export function convertProjectResponseForReport(response, groupBy) {
  const projArr = {};
  let key = "";
  let logTimeMap;

  Object.keys(response.data).forEach(proj => {
    response.data[proj].forEach(log => {
      if ( log.projectInfo[0] && !log.projectInfo[0].deleted ) {
        const { projectName, clientName } = log.projectInfo[0];
        const { projectId } = log;
        const { task } = log;
        const userInfo = log.userInfo[0];
        const userName = userInfo
          ? userInfo.firstName + " " + userInfo.lastName
          : "Deleted user";
        const { userId } = log;

        switch (groupBy) {
          case "project":
            key = projectName;
            break;
          case "client":
            key = clientName;
            break;
          case "person":
            key = userName;
            break;
          case "task":
            key = task;
            break;
          case "date":
            key = moment(proj).format("dd, DD MMM");
            break;
          default:
            return;
        }

        logTimeMap = log.time.reduce((acc, time) => {
          const { amount } = time;
          const { comment } = time;
          const date = moment(time.date).format();
          acc[date] = { amount, comment };
          return acc;
        }, {});

        if (!projArr[key]) {
          projArr[key] = [];
        }

        projArr[key].push({
          projectName,
          clientName,
          projectId,
          userName,
          userId,
          logTimeMap,
          task
        });
      }
    });
  });

  const ordered = {};
  Object.keys(projArr)
    .sort()
    .forEach(function(key) {
      ordered[key] = projArr[key];
    });

  return ordered;
}

export function convertProjectResponseForLogTime(response) {
  return response.data.map(proj => {
    const { projectInfo, task } = proj;
    const projectId = proj.projectId;
    const editable = proj.editable ? proj.editable : null;

    const logTimeMap = proj.time.reduce((acc, time) => {
      const { amount } = time;
      const { comment } = time;
      const date = moment(time.date).format();
      acc[date] = { amount, comment };
      return acc;
    }, {});

    return { projectId, projectInfo, logTimeMap, task, editable };
  });
}

export function convertClientResponse(response) {
  const projArr = {};
  let key = "";

  response.data.map(log => {
    const { projectName, clientName, _id } = log;

    key = clientName;

    if (!projArr[key]) {
      projArr[key] = [];
    }

    return projArr[key].push({
      projectName,
      clientName,
      _id
    });
  });

  const ordered = {};
  Object.keys(projArr)
    .sort()
    .forEach(function(key) {
      ordered[key] = projArr[key];
    });

  return ordered;
}

export function convertProjectResponse(response) {
  const projArr = {};
  let key = "";

  response.data.map(log => {
    const { projectName, clientName, _id } = log;

    key = projectName;

    if (!projArr[key]) {
      projArr[key] = [];
    }

    return projArr[key].push({
      projectName,
      clientName,
      _id
    });
  });

  const ordered = {};
  Object.keys(projArr)
    .sort()
    .forEach(function(key) {
      ordered[key] = projArr[key];
    });

  return ordered;
}

export function convertUserResponse(response) {
  const projArr = {};
  let key = "";

  response.data.map(log => {
    const { firstName, lastName, _id } = log;

    key = firstName + " " + lastName;

    if (!projArr[key]) {
      projArr[key] = [];
    }

    return projArr[key].push({
      firstName,
      lastName,
      _id
    });
  });

  const ordered = {};
  Object.keys(projArr)
    .sort()
    .forEach(function(key) {
      ordered[key] = projArr[key];
    });

  return ordered;
}

export function convertNotification(response) {
  if (Object.keys(response.data).length) {
    return response.data.map(msg => {
      const { message, date } = msg;
      const userId = msg.userId._id;
      const userName = `${msg.userId.firstName} ${msg.userId.lastName}`;

      return { message, userName, date, userId };
    });
  } else {
    return {};
  }
}
