import React, { PureComponent } from "react";
import NoLogtime from "../../../components/NoLogtime";
import Loader from "../../../components/Loader";
import TableHeader from  "../../../components/ReportPage/TableHeader"
import fetchData from "../../../services/fetchData";
import { getUsers } from "../../../services/APIsersices";
import {
  convertProjectResponseForReport,
  convertUserResponse
} from "../../../helpers/convertResponse";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const url = "/api/users/logTime/reports";

class UserContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { startDate, endDate, onlyActive, groupBy } = this.props;

    if (this.props.match.params.id) {
      this.props.onInfoEnter(this.props.match.params.id);
      this.fetchUserData(
        startDate,
        endDate,
        onlyActive,
        groupBy,
        this.props.match.params.id
      );
    } else {
      this.fetchUserData(startDate, endDate, onlyActive, groupBy);
      this.fetcAllUserData();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { startDate, endDate, onlyActive, groupBy } = nextProps;
    if (this.props.match.params.id) {
      this.fetchUserData(
        startDate,
        endDate,
        onlyActive,
        groupBy,
        this.props.match.params.id
      );
    } else {
      this.fetchUserData(startDate, endDate, onlyActive, groupBy);
    }
  }

  fetchUserData = (startDate, endDate, onlyActive, groupBy, handle) => {
    fetchData(startDate, endDate, onlyActive, groupBy, url, handle)
      .then(response => {
        const proj = convertProjectResponseForReport(response, "person");
        this.setState({
          proj
        });
        sessionStorage.setItem("groupBy", groupBy);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetcAllUserData = () => {
    getUsers()
      .then(response => {
        const usersAll = convertUserResponse(response);
        this.setState({ usersAll });
      })
      .catch(error => {
        console.log("Error occured in componentWillMount");
        console.log(error);
      });
  }

  changeGroupBy = () => {
    sessionStorage.setItem("groupByOne", "person");
  }

  renderUsers = (target, index) => {
    let logtimes;
    if (this.state.proj) {
      logtimes = this.state.proj[target];
    }
    let count = 0;
    let id;
    let userName;
    if (logtimes) {
      logtimes.forEach(log => {
        Object.keys(log.logTimeMap).forEach(time => {
          const date = time;
          const amount = log.logTimeMap[date].amount;
          count += amount;
          id = log.userId;
          userName = log.userName;
        });
      });
    }
    return (
      <tr className="ant-table-row ant-table-row-level-0" key={index}>
        {target === userName ? (
          <React.Fragment>
            <td>
              <Link to={`/report/user/${id}`}>{userName}</Link>
            </td>
            <td>
              <Link to={`/report/details/${id}`} title="Detailed report"> {count}</Link>
            </td>
          </React.Fragment>
        ) : (
            <React.Fragment>
              <td> {target}</td>
              <td>0</td>
            </React.Fragment>
          )}
      </tr>
    );
  }

  renderUsersPerId = (target, index) => {
    const logtimes = this.state.proj[target];
    let count = 0;
    let id;
    let userName

    logtimes.forEach(log => {
      Object.keys(log.logTimeMap).forEach(time => {
        const date = time;
        const amount = log.logTimeMap[date].amount;
        count += amount;
        id = log.userId
        userName = log.userName;
      })
    })
    return (
      <tr className="ant-table-row ant-table-row-level-0" key={index}>
        <td>
          <Link onClick={this.changeGroupBy} to={`/report/details/${this.props.match.params.id}/${id}`} title="Detailed report">{userName}</Link>
        </td>
        <td>
          {count}
        </td>
      </tr>
    );
  }

  render() {
    let data;
    this.props.match.params.id
      ? (data = this.state.proj)
      : (data = this.state.usersAll);

    if (!data) {
      return <Loader />;
    } else {
      if (Object.keys(data).length === 0) {
        return <NoLogtime />;
      } else {
        return (
          <React.Fragment>
            {this.props.match.params.id ? <BreadcrumbsItem key="report" to="/report">Time Report</BreadcrumbsItem> : null}
            <div className="ant-table-wrapper gx-table-responsive">
              <div className="ant-spin-nested-loading">
                <div className="ant-spin-container">
                  <div className="ant-table ant-table-default ant-table-scroll-position-left log_time">
                    <div className="ant-table-content">
                      <div className="ant-table-body">
                        <table id="table-to-xls">
                          <thead className="ant-table-thead">
                            <TableHeader columns={['User', 'Time']} class="bg-prim" />
                          </thead>
                          <tbody className="ant-table-body">
                            {this.props.match.params.id
                              ? Object.keys(data).map(this.renderUsersPerId)
                              : Object.keys(data).map(this.renderUsers)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    }
  }
}
export default withRouter(UserContent);
