import React from "react";
import Loader from "../Loader";
import { Card, Table } from "antd";

const columns = [
  {
    title: "Project Name",
    dataIndex: "projectName",
    key: "projectName"
  },
  {
    title: "Client Name",
    dataIndex: "clientName",
    key: "clientName"
  }
];

export default function Projects(props) {
  const data = [];

  if (props.projects) {
    props.projects.map(project => {
      data.push({
        key: project._id,
        projectName: project.projectName,
        clientName: project.clientName
      });
    });
  }

  return props.projects ? (
    <Card title="Your Projects">
    <Table
      className="gx-table-responsive"
      columns={columns}
      dataSource={data}
    />
    </Card>
  ) : (
    <Loader />
  );

  // <div>
  //   <header> Your Projects </header>
  //   <Paper className="table-responsive">
  //     <Table>
  //       <TableHead className="bg-prim">
  //         <TableRow>
  //           <TableCell> Project Name</TableCell>
  //           <TableCell>Client Name </TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {props.projects.map(project => {
  //           return (
  //             <TableRow key={project._id}>
  //               <TableCell> {project.projectName} </TableCell>
  //               <TableCell> {project.clientName} </TableCell>
  //             </TableRow>
  //           );
  //         })}
  //       </TableBody>
  //     </Table>
  //   </Paper>
  // </div>
}
