import React, { Component } from "react";
import { Icon, Row } from "antd";
import AutosizeInput from "react-input-autosize";
import { openNotificationWithIcon } from '../../helpers/helpers'
class EditText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
    document.removeEventListener("keydown", this.escFunction, false);
  }

  handleClick = e => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({ active: false });
  };

  escFunction = event => {
    if (event.keyCode === 27) {
      this.setState({ active: false });
    }
  };

  handleSubmit = value => {
    if (this.props.fieldName === "email") {
      const pattern = new RegExp(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      );
      if (!pattern.test(value)) {
        openNotificationWithIcon('error','not valid')
      } else {
        this.props.callBack(value);
        this.setState({ active: false });
      }
    } else {
      this.props.callBack(value);
      this.setState({ active: false });
    }
  };
  keyPress = (e, name) => {
    if (e.keyCode === 13) {
      this.handleSubmit(name);
    }
  };

  renderChanges = () => {
    let name;
    const input = (
      <Row type="flex" justify="space-between" className="editText">
        <AutosizeInput
          ref="input"
          defaultValue={this.props.value}
          onChange={event => {
            name = event.target.value;
          }}
          onKeyDown={e => this.keyPress(e, name)}
          style={{ background: "inherit" }}
        />
        <button
          style={{ marginLeft: "5px", border: "none", background: "none" }}
          onClick={() => this.handleSubmit(name)}
        >
          <Icon type="check" />
        </button>
      </Row>
    );

    const btn = (
      <div onClick={() => this.onFocus()}>
        <Row type="flex" justify="space-between">
          {this.props.value} <Icon type="edit" />{" "}
        </Row>
      </div>
    );

    return this.state.active ? input : btn;
  };

  onFocus = () => {
    this.setState({ active: true }, () => {
      this.refs.input.focus();
    });
  };

  handleState = () => {
    this.setState({ active: true });
  };

  render() {
    const renderChanges = () => {
      return this.renderChanges();
    };

    return <span ref={node => (this.node = node)}>{renderChanges()}</span>;
  }
}

export default EditText;
