import Moment from "moment";
import { extendMoment } from "moment-range";
import { notification } from 'antd';

const moment = extendMoment(Moment);


export const dateOptions = [
  { label: "Week", value: "isoWeek" },
  { label: "Month", value: "months" },
  { label: "Quarter", value: "quarters" },
  { label: "Year", value: "years" },
  { label: "Custom", value: "custom" }
];

export const groupOptions = [
  { label: "Client", value: "client" },
  { label: "Project", value: "project" },
  { label: "Person", value: "person" },
  { label: "Date", value: "date" },
  { label: "Task", value: "task" }
];

function handleDates(startDate, endDate) {
  return {
    startDate,
    endDate
  };
}
export const timePeriod = {
  isoWeek: handleDates(moment().startOf("isoWeek"), moment().endOf("isoWeek")),
  months: handleDates(moment().startOf("month"), moment().endOf("month")),
  years: handleDates(moment().startOf("year"), moment().endOf("year")),
  quarters: handleDates(moment().startOf("quarter"), moment().endOf("quarter"))
};

export const optionsForTask = [
  { label: "Marketing", value: "Marketing" },
  { label: "Programming", value: "Programming" },
  { label: "Project Management", value: "Project Management" },
  { label: "Software development", value: "Software development" },
  { label: "QA", value: "QA" },
  { label: "Business Development", value: "Business Development" }
];

export function checkPassword(str) {
  const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
  return re.test(str);
}

export function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function(a, b) {
    if (sortOrder === -1) {
      return b[property].toString().localeCompare(a[property]);
    } else {
      return a[property].toString().localeCompare(b[property]);
    }
  };
}

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

export const openNotificationWithIcon = (type, message, description='') => {
  notification[type]({
    message: message,
    description: description,
    style: {
      background: '#55606a'
    },
  });
};
