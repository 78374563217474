import React, { Component } from "react";
import ActionButton from "../../components/ActionButton";
import EditText from "../../components/ProjectTable/EditText";
import { Card, Table, Checkbox, Input, Modal } from "antd";
import {
  getProjects,
  updateProjectName,
  updateProjectState,
  deleteProject
} from "../../services/APIsersices";
import { dynamicSort } from "../../helpers/helpers";
import { openNotificationWithIcon } from "../../helpers/helpers";

const Search = Input.Search;
const confirm = Modal.confirm;

const columns = [
  {
    title: "Project Name",
    dataIndex: "projectName",
    key: "projectName"
  },
  {
    title: "Client Name",
    dataIndex: "clientName",
    key: "clientName"
  },
  {
    title: "Activate/Deactivate",
    dataIndex: "action",
    key: "action"
  },
  {
    title: "Delete",
    dataIndex: "delete",
    key: "delete"
  }
];

class ProjectTable extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      page: 0,
      rowsPerPage: 5,
      searchString: "",
      onlyActive: false
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    getProjects()
      .then(response => {
        const projects = response.data.map(project => ({
          ...project,
          isUpdating: false
        }));
        projects.sort(dynamicSort("-isActive"));
        if (this._isMounted) {
          this.setState({ projects });
        }
      })
      .catch(error => {
        console.log("Error occured in componentWillMount");
        console.log(error);
      });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  handleActivation = project => {
    const projects = this.state.projects.map(element => {
      return element._id === project._id
        ? {
          ...project,
          isUpdating: true
        }
        : element;
    });

    this.setState({ projects });
    updateProjectState({ project })
      .catch(error => {
        console.log("Failed to update the project");
      })
      .then(response => {
        const projects = this.state.projects.map(element => {
          return element._id === response.data._id
            ? { ...response.data, isUpdating: false }
            : element;
        });
        this.setState({ projects });
      });

    project.isActive
      ? openNotificationWithIcon("info", "Deactivated")
      : openNotificationWithIcon("info", "Activated");
  };

  changeProject = (projID, fieldName) => {
    return value => {
      if (!value) {
        return;
      }
      updateProjectName({ projID, value, fieldName })
        .catch(error => {
          console.log("Failed to update the project");
        })
        .then(response => {
          const projects = this.state.projects.map(element => {
            return element._id === response.data._id
              ? { ...response.data }
              : element;
          });

          this.setState({ projects });
          fieldName === "projectName"
            ? openNotificationWithIcon(
              "success",
              "Project name successfully changed"
            )
            : openNotificationWithIcon(
              "success",
              "Client name successfully changed"
            );
        });
    };
  };

  showDeleteConfirm = (project, callBack) => {
    confirm({
      title: 'Are you sure delete this project?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteProject({ project })
          .then(response => {
            callBack(response)
          })
          .catch(() => {
            console.log("Failed to update the project");
          });
      }
    });
  }

  updateProjectsState = response => {
    const projects = this.state.projects.filter(element => {
      return element._id !== response.data._id && !element.deleted;
    });
    this.setState({ projects });
    openNotificationWithIcon(
      "success",
      "Project deleted"
    )
  };

  handleSearch = value => {
    this.setState({
      searchString: value
    });
  };

  toggleChangeActive = () => {
    this.setState(prevState => ({
      onlyActive: !prevState.onlyActive
    }));
  };

  render() {
    const { rowsPerPage, page } = this.state;
    let projects = this.state.projects;
    if (this.state.onlyActive) {
      projects = projects.filter(u => u.isActive === true);
    }
    let search = this.state.searchString.trim().toLowerCase();

    if (search.length > 0) {
      projects = projects.filter(proj => {
        if (proj.projectName.toLowerCase().match(search)) {
          return proj.projectName.toLowerCase().match(search);
        } else if (proj.clientName.toLowerCase().match(search))
          return proj.clientName.toLowerCase().match(search);
      });
    }
    const data = [];
    projects.forEach((project, i) => {
      data.push({
        key: project._id,
        projectName: (
          <EditText
            value={project.projectName}
            callBack={this.changeProject(project._id, "projectName")}
          />
        ),
        clientName: (
          <EditText
            value={project.clientName}
            callBack={this.changeProject(project._id, "clientName")}
          />
        ),
        action: (
          <ActionButton
            value={project.isActive ? "Deactivate" : "Activate"}
            data={project}
            handleClick={this.handleActivation}
            isUpdating={project.isUpdating}
          />
        ),
        delete: (
          <ActionButton
            value={"Delete"}
            data={project}
            handleClick={() => { this.showDeleteConfirm(project, this.updateProjectsState.bind(this)) }}
            isUpdating={project.isActive}
          />
        )
      });
    });
    return (
      <Card title="Projects">
        <div>
          <Search
            placeholder="Search"
            onSearch={value => this.handleSearch(value)}
            style={{ width: 200 }}
          />
        </div>
        <Checkbox
          name="onlyActive"
          onChange={this.toggleChangeActive}
          checked={this.state.onlyActive}
        >
          Active projects only
        </Checkbox>
        <Table
          className="gx-table-responsive"
          columns={columns}
          dataSource={data}
          bordered
          pagination={{
            count: Object.keys(projects).length,
            defaultPageSize: rowsPerPage,
            page: page,
            onChangePage: this.handleChangePage
          }}
        />
      </Card>
    );
  }
}

export default ProjectTable;
