import React, { Component } from "react";
import { Link } from "react-router-dom";
import UserInfo from "../components/UserInfo";
import { Icon, Layout, Menu } from "antd";
import logoWhite from '../assets/images/logo_white.png'

const { Header } = Layout;

class Navbar extends Component {
  state = {
    auth: true,
    anchorEl: null,
    navCollapsed: ""
  };
  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const menuItems = [
      {
        title: "Add user",
        protected: true,
        url: "/userForm",
        allowedRoles: ["admin"]
      },
      {
        title: "Add project",
        protected: true,
        url: "/projectForm",
        allowedRoles: ["admin"]
      },
      {
        title: "Users",
        protected: true,
        url: "/userTable",
        allowedRoles: ["admin"]
      },
      {
        title: "Projects",
        protected: true,
        url: "/projectTable",
        allowedRoles: ["admin"]
      },
      {
        title: "Time",
        protected: true,
        url: "/",
        allowedRoles: ["admin"]
      },
      {
        title: "Reports",
        protected: true,
        url: "/report",
        allowedRoles: ["admin"]
      }
    ];

    return (
      <div className="gx-header-horizontal gx-header-horizontal-dark">
        <Header className="gx-header-horizontal-main">
          <div className="gx-container">
            <div className="gx-header-horizontal-main-flex">
              <Link
                to="/"
                className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
              >
                <img
                  alt="Brand"
                  style={{ width: "100px", marginRight: "15px" }}
                  src={logoWhite}
                />
              </Link>

              <ul className="gx-header-notifications gx-ml-auto">
                {this.props.userData.email &&
                  this.props.userData.idRole.some(
                    r => ["admin"].indexOf(r.name) >= 0
                  ) ? (
                    <li className="gx-notify">
                      <Link to="/notification">
                        <div className="gx-notify">
                          <span className="gx-pointer gx-status-pos gx-d-block">
                            <Icon type="bell" />
                            <span
                              className={
                                this.props.notific
                                  ? "gx-status gx-status-rtl gx-small gx-blink"
                                  : ""
                              }
                            />
                          </span>
                        </div>
                      </Link>
                    </li>
                  ) : null}
                {this.props.userData.userImage
                  ? auth && (
                    <li className="gx-user-nav">
                      <UserInfo sourse={this.props.userData.userImage} />
                    </li>)
                  : null}
              </ul>
            </div>
          </div>
        </Header>
        <div className="gx-header-horizontal-nav gx-d-none gx-d-lg-block">
          <div className="gx-container">
            <Menu mode="horizontal"
             theme="dark">
              {this.props.userData.email
                ? menuItems.map((i, index) =>
                  !i.protected ||
                    this.props.userData.idRole.some(
                      r => i.allowedRoles.indexOf(r.name) >= 0
                    ) ? (
                      <Menu.Item key={index}>
                        <Link className="nav-link" to={i.url}>
                          {i.title}
                        </Link>
                      </Menu.Item>
                    ) : null
                )
                : null}
            </Menu>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
