import React, { Component } from "react";
import Button from "../Button";
import { Modal, Select, Form } from "antd";
const Option = Select.Option;
const FormItem = Form.Item;


class ModalForAddRow extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const projectsOption = [], tasksOption = [];
        for (let i = 0; i < this.props.optionsForProject.length; i++) {
            projectsOption.push(
                <Option key={this.props.optionsForProject[i].value}>{this.props.optionsForProject[i].label}</Option>
            );
        }

        for (let i = 0; i < this.props.optionsForTask.length; i++) {
            tasksOption.push(
                <Option key={this.props.optionsForTask[i].value}>{this.props.optionsForTask[i].label}</Option>
            );
        }
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                className="modalSelect"
                title="New row"
                visible={this.props.showAddRow}
                onCancel={this.props.handleClose}
                footer={[
                    <Button
                        key="1"
                        action={() => {
                            this.props.handleSaveRow();
                            this.props.handleClose();
                            this.props.form.resetFields();
                        }}
                        value={"Save"}
                    />,
                    <Button key="2" action={() => {
                        this.props.handleClose();
                        this.props.form.resetFields();
                    }} value={"Close"} />
                ]}
            >
                <Form>
                    <FormItem label="Project">
                        {getFieldDecorator("projectId")(<Select
                            className="gx-mr-3 gx-mb-3"
                            onChange={e => this.props.handleSelectChange("projectId", e)}
                        >
                            {projectsOption}
                        </Select>)}
                    </FormItem>
                    <FormItem label="Task">
                        {getFieldDecorator("tasks")(
                            <Select
                                className="gx-mr-3 gx-mb-3"
                                onChange={e => this.props.handleSelectChange("tasks", e)}
                            >
                                {tasksOption}
                            </Select>)}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

export default Form.create()(ModalForAddRow);