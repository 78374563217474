import React, { Component } from "react";
import { openNotificationWithIcon } from "../../helpers/helpers";
import { createProject } from "../../services/APIsersices";
import { Button, Card, Checkbox, Form, Input } from "antd";
import { formItemLayout } from "../../helpers/helpers";

const FormItem = Form.Item;

class ProjectForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: "",
      clientName: "",
      isActive: true
    };
  }

  toggleChangeActive = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive
    }));
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          projectName: this.state.projectName,
          clientName: this.state.clientName,
          isActive: this.state.isActive
        };

        createProject(data)
          .then(() => {
            openNotificationWithIcon("success", "Project successfully created");
            this.props.form.resetFields();
          })
          .catch(error =>
            openNotificationWithIcon("error", "Project not created", error)
          );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Card className="gx-card">
        <Form
          onSubmit={this.handleSubmit}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem {...formItemLayout} label="Name of the client">
            {getFieldDecorator("clientName", {
              value: this.state.clientName,
              rules: [
                {
                  required: true,
                  message: "Please input name of the client"
                }
              ]
            })(<Input name="clientName" onChange={this.handleChange} />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Name of the project">
            {getFieldDecorator("projectName", {
              value: this.state.projectName,
              rules: [
                { required: true, message: "Please input name of the project" }
              ]
            })(<Input name="projectName" onChange={this.handleChange} />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Active"
            className="checkbox"
          >
            {getFieldDecorator("isActive", {
              initialValue: true
            })(
              <Checkbox
                onChange={this.toggleChangeActive}
                checked={this.state.isActive}
              />
            )}
          </FormItem>
          <FormItem>
            <Button type="primary" className="gx-mb-0" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </Card>
    );
  }
}

export default Form.create()(ProjectForm);
