import React from "react";
import Button from "../../components/Button";
import { changeUserPassw } from "../../services/APIsersices";
import { checkPassword } from "../../helpers/helpers";
import { Card, Input, Form } from "antd";
import { formItemLayout } from "../../helpers/helpers";
import { openNotificationWithIcon } from "../../helpers/helpers";

const FormItem = Form.Item;

class Security extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      showPassword: false,
      validPassw: false
    };
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handlechangePassword = event => {
    event.preventDefault();
    if (!checkPassword(this.state.password)) {
      this.setState({
        validPassw: true
      });
      return false;
    } else {
      const data = {
        id: this.props.user._id,
        text: this.state.password
      };
      changeUserPassw(data)
        .catch(error => {
          openNotificationWithIcon(
            "error",
            "Failed to update the project",
            error
          );
        })
        .then(response => {
          openNotificationWithIcon("success", "Password successfully changed");
        });
      this.setState({
        password: "",
        validPassw: false
      });
    }
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Card title="Change Your Password ">
        <Form className="gx-signin-form gx-form-row0">
          <FormItem {...formItemLayout} label="Password">
            {getFieldDecorator("password", {
              initialValue: this.state.password
            })(
              <Input
                name="password"
                type="password"
                onChange={this.handleChange("password")}
              />
            )}
          </FormItem>
          {this.state.validPassw ? (
            <p>
              Password must contain minimum 6 characters, at least one letter
              and one number
            </p>
          ) : null}
          <FormItem>
            <Button
              value={"Change password"}
              action={this.handlechangePassword}
            />
          </FormItem>
        </Form>
      </Card>
    );
  }
}

export default Form.create()(Security);
