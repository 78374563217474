import React from "react";
import { Button, Icon } from "antd";

const ButtonGroup = Button.Group;

export default function HeaderButtons(props) {
  let menuItems = [];
  if (props.btnForLogTime) {
    menuItems = [
      {
        type: "button",
        title: "Previous Week",
        itemClass: "left",
        onClick: props.handlePreviousWeek,
        disabled: props.disabled
      },
      {
        type: "button",
        value: "This Week",
        title: "This Week",
        onClick: props.handleCurentWeek
      },
      {
        type: "button",
        title: "Next week",
        itemClass: "right",
        onClick: props.handleNextWeek
      }
    ];
  } else {
    menuItems = [
      {
        type: "button",
        title: "Previous",
        itemClass: "left",
        onClick: props.handlePrevious
      },
      {
        type: "button",
        title: "Next",
        itemClass: "right",
        onClick: props.handleNext
      }
    ];
  }

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group d-flex justify-content-end" role="group">
        <ButtonGroup>
          {menuItems.map((i, index) => (
            <Button
              disabled={i.disabled}
              key={index}
              type={i.type}
              title={i.title}
              onClick={i.onClick}
            >
              {i.itemClass ? <Icon type={i.itemClass} /> : i.value}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </div>
  );
}
