import {
  SET_LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  RECEIVE_USER_DATA
} from "../actions/actions.js";

const initialState = {
  email: "",
  password: "",
  loggedIn: false,
  isLoginPending: false
};

export default function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOGIN_PENDING:
      state = {
        ...state,
        isLoginPending: true
      };
      break;

    case LOGIN_SUCCESS:
      state = {
        ...state,
        loggedIn: true,
        userData: action.payload,
        isLoginPending: false
      };
      break;
    case LOGOUT:
      state = {
        ...state,
        loggedIn: false,
        userData: {}
      };
      break;
    case LOGIN_FAILURE:
      state = {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        isLoginPending: false
      };
      break;
    case RECEIVE_USER_DATA:
      state = {
        ...state,
        userData: action.payload
      };
      break;
    default:
      return state;
  }
  return state;
}
