import axios from '../core/HttpClient';

const urlUser = "/api/users"
const urlOneUser = "/api/users/getOne"
const urlChangePassW = "/api/users/change/password"
const urlChangeUser = '/api/users/updateInfo';
const updatePhoto ="/api/users/updatePhoto"
const updateInfoOnSubmit ="/api/users/updateInfoOnSubmit"
const urlUpdateProj = '/api/users/projupdate'
const urlLogTimeData = "/api/users/logTime";
const urldeleteLogTime = "/api/users/logTime/delete";

const urlReset = "/api/auth/forgotPassword"

const urlProject = "/api/projects"
const urlUpdateProject = "/api/projects/updateProjName"
const urlUpdateProjectState = "/api/projects/update"
const urldeleteProj = "/api/projects/delete"
const urlRole = "/api/role"

const auth ="/api/auth/login"

const urlNotification = "/api/notification"

const urlReply = "/api/notification/reply"

export function getUsers(){
    return(
        axios.get(urlUser)
    )
}

export function getOneUser(){
    return(
        axios.get(urlOneUser)
    )
}
export function changeUserData (data){
    return(
        axios.post( urlChangeUser, data)
    )
}

export function changeUserPassw (data){
    return(
        axios.post( urlChangePassW, data)
    )
}

export function updateUserInfoOnSubmit (data){
    return(
        axios.post( updateInfoOnSubmit, data)
    )
}

export function updateUserPhoto (data){
    return(
        axios.post( updatePhoto, data)
    )
}

export function getProjects(){
    return(
        axios.get(urlProject)
    )
}

export function createProject(data){
    return(
        axios.post(urlProject, data)
    )
}

export function updateProjData (data){
    return(
        axios.post(urlUpdateProj, data)
    )
}

export function deleteProject (data){
    return(
        axios.post(urldeleteProj, data)
    )
}

export function updateProjectName (data){
    return(
        axios.post( urlUpdateProject, data)
    )
}

export function updateProjectState (data){
    return(
        axios.post( urlUpdateProjectState, data)
    )
}

export function submitLogTime (data){
    return(
        axios.post(urlLogTimeData, data)
    )
}

export function  fetchLogTimeData (startDate, endDate){
    return axios.get(urlLogTimeData, {
        params: {
            startDate: startDate.format(),
            endDate: endDate.format()
        }
    })
}

export function  deleteLogTime (startDate, endDate, proj){
    return axios.delete(urldeleteLogTime, {
        params: {
            startDate: startDate.format(),
            endDate: endDate.format(),
            projectId: proj.projectId,
            task: proj.task
        }
    })
}

export function getRoles(){
    return(
        axios.get(urlRole)
    )
}

export function authentication (data){
    return(
        axios.post(auth, data)
    )
}

export function resetPassword (data){
    return(
        axios.post(urlReset, data)
    )
}

export function sendNotification (data){
    return(
        axios.post(urlNotification, data)
    )
}

export function getNotification (){
    return(
        axios.get(urlNotification)
    )
}

export function sendReply (data){
    return(
        axios.post(urlReply , data)
    )
}
