import { RECEIVE_NOTIFIC } from "../actions/actions.js";

const initialState = {
  msg: 0
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_NOTIFIC:
      state = {
        ...state,
        msg: action.payload,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default notificationReducer;
