import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Projects from "../../components/ProfilePage/Projects";
import BasicInfo from "./BasicInfo";
import Security from "./Security";
import Error from "../../components/ProfilePage/Error";
import { fetchUserData } from "../../actions/actions";
import { openNotificationWithIcon } from "../../helpers/helpers";
import { NavLink } from "react-router-dom";
import { Layout, Menu, Avatar } from "antd";
import {
  updateUserInfoOnSubmit,
  updateUserPhoto
} from "../../services/APIsersices";

const { Sider, Content } = Layout;

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };
  }
  componentDidMount() {
    this.setState({
      user: this.props.userData
    });
  }

  handleSubmit = () => (firstName, lastName, path) => {
    const userID = this.props.userData._id;

    if (firstName || lastName) {
      updateUserInfoOnSubmit({ userID, firstName, lastName })
        .catch(error => {
          console.log("Failed to update the project");
        })
        .then(response => {
          const user = response.data;
          user.firstName = response.data.firstName;
          user.lastName = response.data.lastName;
          this.setState({ user });
        });
    }

    if (path) {
      const formData = new FormData();

      formData.append("userImage", path, path.name);
      formData.append("userID", userID);
      formData.append("path", path.name);
      updateUserPhoto(formData)
        .catch(error => {
          console.log("Failed to update the user");
        })
        .then(response => {
          const user = response.data;
          this.setState({ user });
          this.props.fetchUserData();
        });
    }
    openNotificationWithIcon("success", "Changes made successfully");
  };

  renderProjects = () => {
    return <Projects projects={this.state.user.projects} />;
  };

  renderBasicInfo = () => {
    return (
      <BasicInfo
        user={this.state.user}
        handleSubmit={this.handleSubmit()}
      />
    );
  };

  renderSecurity = () => {
    return <Security user={this.props.userData} />;
  };

  render() {
    const { path } = this.props.match;

    return (
      <Layout>
        <Sider width={200}>
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ height: "100%", borderRight: 0 }}
          >
            {this.state.user.userImage ? (
              <Menu.Item>
                <Avatar
                  src={this.state.user.userImage}
                  className="gx-size-40 gx-pointer gx-mr-3"
                  alt=""
                />
                <span className="gx-avatar-name">
                  {" "}
                  {this.state.user.firstName} {this.state.user.lastName}
                </span>
              </Menu.Item>
            ) : null}
            <Menu.Item>
              <NavLink className="nav-link" to={`${path}/projects`}>
                Projects
              </NavLink>
            </Menu.Item>
            <Menu.Item>
              {" "}
              <NavLink className="nav-link" to={`${path}/basicinfo`}>
                Basic Info
              </NavLink>
            </Menu.Item>
            <Menu.Item key="3">
              <NavLink className="nav-link" to={`${path}/security`}>
                Security
              </NavLink>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content>
          <Switch>
            <Route path={`${path}`} render={this.renderProjects} exact />
            <Route
              path={`${path}/projects`}
              render={this.renderProjects}
              exact
            />
            <Route
              path={`${path}/basicinfo`}
              render={this.renderBasicInfo}
              exact
            />
            <Route
              path={`${path}/security`}
              render={this.renderSecurity}
              exact
            />
            <Route component={Error} />
          </Switch>
        </Content>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUserData: data => dispatch(fetchUserData(data))
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(ProfilePage)
);
